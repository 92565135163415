<template>
  <div id="PartTwo" class="bg-white rounded-xl shadow-md" data-aos="fade-right" data-aos-easing="ease-out-cubic">
    <div class="px-8 md:px-0 mt-30 mb-16 rounded-xl leading-normal">
      <div class="p-grid p-fluid p-jc-center p-m-4">
        <h1 class="my-4 text-2xl leading-tight">
          Parte B: Escoge de entre cada par de alternativas, la que más te identifique
          <span class="p-error">({{ selectedItems.length }} de {{ partB.length }} )</span>
        </h1>
      </div>

      <div class="grid mx-4">
        <div v-for="(pair, index) of partB" :key="index" class="p-1 rounded-md bg-gray-100">
          <div class="flex justify-center">
            <span class="text-indigo-700">{{ index + 1 }}.</span>
          </div>
          <div class="p-grid p-fluid p-jc-center py-2">
            <small class="mx-2">Caracteristica 1</small>
            <small class="mx-2">Caracteristica 2</small>
          </div>
          <div class="p-grid p-fluid p-jc-center py-2">
            <div v-for="(item, i) of pair.options" :key="i" class="custom-check mx-1" @click.stop="selectItem(pair.id, item, i)">
              {{ item.name }}
              <input :checked="item.name === pair.selected ? true : false" type="radio" />
              <span class="checkmark ml-2 text-sm"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center p-m-4">
        <Button class="p-mr-3" label="Volver" icon="pi pi-angle-left" @click="prevStep()" />
        <Button
          class="p-ml-3"
          label="Continuar"
          icon="pi pi-angle-right"
          iconPos="right"
          :disabled="selectedItems.length >= partB.length ? false : true"
          @click="nextStep()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from "vue";
import { CoreStore } from "@/store";
import { partTwoData } from "./TestData";

export default defineComponent({
  name: "PartTwo",

  emits: ["next-step", "prev-step"],

  setup(_, ctx) {
    const partB = ref(partTwoData);
    const selectedItems = ref([]);

    watchEffect(() => {
      CoreStore().test.partB = partB.value;
    });

    const selectItem = (id, option, subindex) => {
      const question = partB.value.find((item) => {
        return item.id === id;
      });

      question.selected = option.name;

      question.options.forEach((item, index) => {
        if (index === subindex) item.value = true;
        else item.value = false;
      });

      const exist = selectedItems.value.find((item) => {
        return item.id === id;
      });

      if (exist) {
        exist.selected = option;
      } else {
        selectedItems.value.push({ id, selected: option });
      }
    };

    const nextStep = async () => {
      await CoreStore()
        .finishTest()
        .then(() => {
          ctx.emit("next-step", { pageIndex: 2, next: true });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const prevStep = () => {
      ctx.emit("prev-step", { pageIndex: 2, next: true });
    };

    return { partB, selectedItems, selectItem, nextStep, prevStep };
  },
});
</script>

<style style="scss" scoped>
/* layout */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-gap: 16px;
  grid-auto-flow: dense;
}

/* The container */
.custom-check {
  display: block;
  position: relative;
  padding-left: 38px;
  padding-top: 4px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.checkmark:hover {
  transform: scale(1.1);
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #1769aa;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>
