
import { defineComponent, reactive, computed } from "vue";
import { CoreStore } from "@/store";

export default defineComponent({
  name: "Answers",

  setup() {
    const coreStore = CoreStore();

    const state = reactive({
      isLoading: computed(() => coreStore.test.isLoading),
      answers: computed(() => coreStore.test.professionalData.answers),
    });

    return { state };
  },
});
