export const workProfiles = {
  L: {
    shortDesc:
      "Decidido y Fuerte, gran capacidad para tomar iniciativas. Emprendedor. Independiente. Explosivo. Extrovertido. Egocéntrico. Rápido. Lleno de energía",
    description:
      "El tipo poderoso y dominante. Las personas tipo Líder son seguras de sí mismas, fuertes y capaces de imponerse. Protectoras, ingeniosas y decididas, también resultan orgullosas y dominantes; piensan que deben estar al mando de su entorno.  En su mejor aspecto, los Líderes  se controlan y usan su fuerza para mejorar la vida de otras personas",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Muy enérgico y productivo",
        "Asume roles de liderazgo",
        "No le cuesta tomar decisiones ni le teme al conflicto",
        "Busca el poder y se hace del control para lograrlo",
      ],
      "ESTILO DE LIDERAZGO": [
        "Protector. Su equipo es intocable",
        "Dominante y agresivo. Goza de alta autoestima",
        "Actúa rápidamente",
        "Entrega dirección y motivación a sus seguidores",
      ],
      "TRABAJO EN EQUIPO": [
        "Prefiere la sensación de control, incluso cuando no es quien figura",
        "Le cuesta confiar, establece límites claros",
        "Recomienda reglas de base para mejorar la gestión",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Persuade a la gente con su seguridad y la fuerza de su personalidad",
      "TOMA DE DECISIONES": "Actúa, sin mayores cuestionamientos",
      "REGLAS Y AUTORIDAD": "Es rebelde frente a la norma y a la autoridad. Cuando está al mando, crea normas para después obviarlas",
      "TIPO DE OBJETIVOS": "Que reflejen una visión general e influyan en la organización",
      "TIPO DE TAREAS": "Le gusta que su trabajo sea desafiante y tenga resultados concretos; de preferencia, con objetivos comunes",
      "MANEJO DEL TIEMPO":
        "Controla el tiempo midiendo lo que es significativo hacer en cada momento. Se impacienta si tiene que contar con límites que le imponen. Prefiere hacerlo luego sin dilaciones inútiles, lo que da la impresión de ímpetu a su acción",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": [
        "La injusticia",
        "Que no aborden directamente los temas",
        "Que la gente no se responsabilice por sus actos",
        "Sentirse despreciado",
        "La falta de sinceridad",
      ],
      COMPORTAMIENTO: [
        "Experimenta oleadas de ira que lo impulsan a la acción",
        "Hace una evaluación rápida de información y sentimientos",
        "Evita sentirse vulnerable o fuera de control",
        "Puede aislarse por completo",
        "Busca consejeros de su confianza",
      ],
      "CÓMO ABORDARLO": [
        "Sea directo",
        "Sea sincero",
        "Escuche y note la intensidad de sus sentimientos",
        "No muestre debilidad o inseguridad",
        "Evite el lenguaje que él/ella pueda percibir como acusatorio",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Sea breve, directo y sincero",
        "Dé una panorámica general del asunto",
        "Deje al receptor responder tan a menudo como desee",
      ],
      "EFECTO DE LA CONDUCTA": [
        "Mencione su fe absoluta en la capacidad de la persona para manejar la situación",
        "Sutilmente, indíquele que cuenta con un buen respaldo",
        "Demuéstrele que usted no se pone del lado de nadie y que será justo",
      ],
      "CONDUCTA DESEADA": [
        "Haga que la persona tenga la sensación de que controla la situación",
        "Procure que en sus comentarios sigan siendo directos y considerados",
      ],
    },
  },
  P: {
    shortDesc:
      "Receptivo, tranquilizador y complaciente. Generosos y abiertos de mente. Buscarán acuerdos con amabilidad. Moderados en sus juicios, pacientes y con gran autocontrol",
    description:
      "El tipo acomodaticio, humilde. Los Mediadores son confiados y estables. Son afables, bondadosos, se acomodan con facilidad y ofrecen su apoyo, dispuestos a transigir con los demás para mantener la paz. Desean que todo vaya sobre ruedas, sin conflictos. En su mejor aspecto, los Mediadores son capaces de unir a las personas y solucionar conflictos",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Evitan los conflictos: Prefieren que la “familia laboral” se lleve bien",
        "No buscan ni piden ascensos, aunque esperan reconocimiento",
        "Gustan de procedimientos, órdenes y recompensas bien definidas",
        "Funcionan en automático, produciendo una gran cantidad de trabajo",
        "Dilatan una decisión recopilando datos",
        "Dificultad para priorizar tareas",
      ],
      "ESTILO DE LIDERAZGO": [
        "Tienden a ser de los líderes más abiertos; “lo que ves es lo que hay”",
        "Encuentran formas de juntar a la gente e involucrar a todos",
        "Son mediadores y pacificadores por esencia",
        "En sus equipos hay orden y tranquilidad sin que tengan que imponerse",
        "En su presencia, los conflictos rebajan de intensidad",
        "Tienen un poco de dificultad con las personas hiperactivas",
      ],
      "TRABAJO EN EQUIPO": [
        "Piensa que formar parte de un grupo armónico  resulta muy placentero",
        "Ambivalente en cuanto a los acuerdos: le gusta el consenso pero no las reglas arbitrarias",
        "Prefiere ayudar a decir que no. Cuando nota diferencias en su equipo, intenta armonizarlas",
        "Se desarrolla mejor en condiciones de apoyo positivo",
        "No toma responsabilidades que puedan generar expectativas incumplibles en los demás",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Armonizando y tratando de predicar con el ejemplo",
      "TOMA DE DECISIONES": "Elige, generalmente, la que tiene mayor aprobación",
      "REGLAS Y AUTORIDAD":
        "Cuando su jefe necesita de su ayuda, siempre la tiene. Ambivalente frente a la autoridad: es tozudo para seguir órdenes de otros",
      "TIPO DE OBJETIVOS": "Concretos y significativos, desarrollados por consenso",
      "TIPO DE TAREAS":
        "Aunque le cuesta empezar los proyectos, prefiere avanzar en base a un cronograma y dejar espacio para otras tareas menos apremiantes",
      "MANEJO DEL TIEMPO":
        "Sentido metronómico: cada momento tiene la misma duración e importancia. Cumpliendo el horario mantienen a raya el contenido emocional. Relación con el tiempo es relajada; son metódicos y rutinarios",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": [
        "Ruptura de la paz y armonía",
        "Que le digan lo que tiene que hacer",
        "Sentirse ignorado",
        "La grosería de los demás",
        "La hostilidad manifiesta",
        "Sentir que se aprovechan de él",
        "La confrontación",
        "No sentirse apoyado",
      ],
      COMPORTAMIENTO: [
        "No dice nada",
        "La tensión facial puede dar una ligera pista de su enfado",
        "Tal vez no sea consciente de estar molesto",
        "Puede desplazar la ira contra un tercero",
        "Puede arrastrar rabia durante largos períodos de tiempo",
      ],
      "CÓMO ABORDARLO": [
        "Pregúntele el por qué de su enfado de un modo amable y sencillo",
        "Pregúntele por la razón de su enojo de manera indirecta y poco insistente",
        "Escuche con atención",
        "Reconozca que El Mediador ha expresado su enfado de forma clara",
        "Exponga otros puntos de vista sin invalidar por ello los sentimientos del Mediador",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Desarrolle la compenetración",
        "Trate de no emitir juicios",
        "Intente participar en el proceso de reflexión del Mediador",
      ],
      "EFECTO DE LA CONDUCTA": ["Trate de ver la situación desde múltiples puntos de vista", "Ofrezca el contexto general de la situación"],
      "CONDUCTA DESEADA": [
        "Proponga sus ideas, pero no exija que El Mediador emprenda una acción en concreto",
        "Ayude al Mediador a sentirse cómodo en la acción que decida emprender",
      ],
    },
  },
  I: {
    shortDesc:
      "Racional, argumentativo y concienzudo. Alto grado de responsabilidad y del deber. Inteligente, disciplinado y meticuloso. Serio, estricto, confiable y noble",
    description:
      "El tipo idealista de sólidos principios. Éticos y concienzudos, poseen un fuerte sentido del bien y el mal. Son profesores y cruzados, se esfuerzan siempre por mejorar las cosas, pero temen cometer errores. Tratan de mantener valores elevados, pero pueden resultar críticos. En su mejor estado, el Perfeccionista es sabio, perceptivo, realista y noble",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Capaces de producir ”bien” grandes volúmenes de trabajo",
        "Prácticos. Prefieren hacer a sentir",
        "Gustan de organigramas y pautas específicos. Roles definidos",
        "Se entregan al trabajo y les cuesta delegar. Temen equivocarse",
        "No asumen riesgos innecesarios",
      ],
      "ESTILO DE LIDERAZGO": [
        "Grandes líderes por su sentido del bien y el mal. Muy autocríticos y tensos”",
        "Le gusta que le hagan caso y presiona para que las cosas se hagan bien",
        "No puede evitar señalar las cosas que están erróneas",
        "Prefiere la disciplina y controlar que la norma se cumpla",
        "Defiende a ultranza a desvalidos y esforzados",
      ],
      "TRABAJO EN EQUIPO": [
        "Le frustra trabajar en grupo",
        "Es importante que las personas ocupen su lugar y utilicen su rol",
        "No le gusta quedar comprometido por errores ajenos",
        "Si hay alguna falla, se aísla hasta encontrar su origen",
        "Sugiere reglas para trabajar mejor en equipo",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Persuade a la gente con su honestidad y la razonabilidad de sus argumentos",
      "TOMA DE DECISIONES": "Analiza y evalúa cada detalle",
      "REGLAS Y AUTORIDAD":
        "Prefiere liderar un equipo, fijar las reglas y controlar que se cumplan. Teme equivocarse y, a veces, tiende a provocar luchas de poder o peleas acerca de quién tiene la razón",
      "TIPO DE OBJETIVOS": "Claros, realistas y con un propósito determinado",
      "TIPO DE TAREAS": "Prefiere actividades donde audite, controle o lleve un orden, de preferencia tener claro qué hacer cada día",
      "MANEJO DEL TIEMPO":
        "El tiempo lo domina, lo empuja contra su voluntad. Nunca dispone del suficiente para hacer las cosas bien. Lo organiza y utiliza responsablemente. Lo valora y no lo derrocha inútilmente",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": [
        "Ser criticado",
        "Que los otros no se atengan a lo pactado",
        "Los cambios unilaterales del otro en un plan",
        "Sentirse engañado",
      ],
      COMPORTAMIENTO: ["Comentarios bruscos", "Acusaciones relativas a otros temas", "Expresiones de ira no verbales", "Guarda silencio"],
      "CÓMO ABORDARLO": [
        "Enfóquelo como la resolución de un problema",
        "Déjele tiempo para asimilar sus sentimientos",
        "Dele cierto orden a la conversación",
        "Déjelo hablar primero",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Empiece con un cumplido sincero",
        "Ofrezca ejemplos exactos y detallados",
        "Consiga el asentimiento del receptor",
      ],
      "EFECTO DE LA CONDUCTA": ["Evite palabras que impliquen “bien” y “mal”", "Hable de corazón"],
      "CONDUCTA DESEADA": ["Ayude a su interlocutor a sentir que puede controlar los resultados"],
    },
  },
  O: {
    shortDesc:
      "Humanitario y Bondadoso. Experto articulador social. Apoyador de líderes y confidente emocional de sus pares. Encantadores y eficaces. Representan el “Poder detrás del Trono”",
    description:
      "El tipo preocupado, orientado a los demás. Los Coordinadores son comprensivos, sinceros y bondadosos; son amistosos y abnegados. Desean intimar con los demás y suelen hacer cosas por ellos para sentirse necesitados. Les cuesta reconocer sus propias necesidades. En su mejor aspecto, los Coordinadores son generosos y altruistas",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Trabajan por el respeto de las personas de la elite de su medio",
        "La seguridad radica en complacer a la autoridad. Temen enfrentarse al poder ellos solos",
        "Controlan las interacciones que se producen en la oficina. Son los conductores de la información",
        "Eficientes en la medida que su trabajo sea reconocido por el poder",
      ],
      "ESTILO DE LIDERAZGO": [
        "Líderes más considerados y sensibles de todos los estilos de gestión",
        "Le acomoda y hace sentir bien tener gente que dependa de él",
        "Mayor orgullo:  saber equilibrar el ambiente en momentos difíciles",
        "A menudo se vuelve cercano a los integrantes de su equipo y mantiene contacto activo con ellos",
        "Acostumbran a aconsejar a las personas sobre su vida personal",
      ],
      "TRABAJO EN EQUIPO": [
        "Sienten que son la unión del grupo",
        "Les gusta que los demás estén bien en su presencia y se esfuerzan mucho por superar los obstáculos en las relaciones",
        "Suelen respaldar a los favoritos",
        "Empuja al grupo a adoptar acuerdos claros y concienzudos",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO":
        "Son el Poder detrás del Trono. No hay nadie que conozca mejor que ellos a las personas que trabajan en el lugar",
      "TOMA DE DECISIONES": "Espontáneamente, tienen la convicción que su intuición no falla",
      "REGLAS Y AUTORIDAD": "Suele ser poco disciplinado; sabe cómo ser espontáneo e improvisador. Trabaja para ser un aporte para su jefe",
      "TIPO DE OBJETIVOS": "Comunes y significativos, que requieran el talento de los demás",
      "TIPO DE TAREAS":
        "Prefiere trabajar para ayudar a la gente en una relación uno a uno. Se orientan más hacia las personas que a las tareas. Siente que muchas tareas ingratas recaen sobre sus hombros",
      "MANEJO DEL TIEMPO": "Centrados en el presente, buscando relaciones interpersonales y tratando de generar ocasiones de encuentro",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": ["Tener la sensación de que lo dejen de lado", "Sentir que no se le agradece algo", "No sentirse escuchado"],
      COMPORTAMIENTO: [
        "Se guarda los sentimientos para sí durante largos períodos de tiempo",
        "Se muestra muy emocionado cuando al fin dice algo",
        "Planea lo que va a decir, un discurso que incluye cómo se siente, por qué se siente así y en qué, según él, ha fallado la otra persona",
      ],
      "CÓMO ABORDARLO": [
        "Déjele hablar cuanto quiera",
        "Haga preguntas aclaratorias",
        "Comuníquele sus puntos de vista",
        "Asegúrese de validar su perspectiva",
        "Comente sentimientos y pensamientos",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Trate de parecer amigable y optimista",
        "Asegúrese de que la conversación es absolutamente confidencial",
        "Proporcione más detalles sólo si se los piden",
      ],
      "EFECTO DE LA CONDUCTA": [
        "Refuerce el valor que da el receptor a las relaciones positivas",
        "Sonría y mantenga una actitud positiva. Subraye el efecto de la conducta en los demás",
        "Pregunte por sus sentimientos",
      ],
      "CONDUCTA DESEADA": ["Deje muy claro que apoya al Coordinador", "Refuerce su opinión positiva de esa persona"],
    },
  },
  V: {
    shortDesc:
      "Seguro de sí mismo, motivador, competitivo y ejecutante. Profesional, estimulante, carismático y entusiasta. Adaptable, ambicioso y consciente de su imagen",
    description:
      "El tipo adaptable y orientado al éxito. Las personas del tipo Competente son seguras de sí mismas, atractivas y encantadoras. Ambiciosas, eficientes y enérgicas. Muy motivadas por el progreso personal. Suelen preocuparse por su imagen y por lo que los demás piensen de ellas. Trabajólicos y Competitivos. En su mejor aspecto, el Competente es un modelo que inspira a otras personas",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Orientados a la tarea y los objetivos",
        "Compiten por el éxito, necesitan aprobación de los demás",
        "Habilidosos: expertos instantáneos",
        "Valoran más el producto que el proceso. No se quedan en detalles",
      ],
      "ESTILO DE LIDERAZGO": [
        "Carismáticos y adaptables, con necesidad de ganar",
        "Buscan ser sobresalientes y estimados",
        "Se creen algo superior a la mayoría",
        "Rápidos para ajustarse a casi cualquier situación",
      ],
      "TRABAJO EN EQUIPO": [
        "Priorizan la tarea sobre las relaciones y suelen volverse competitivos",
        "Prefieren a los miembros eficientes y calificados del grupo",
        "Ejecutantes: motivan a los demás para mejorar su desempeño",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Ejercen su poder sobre los demás y pueden competir por los roles de liderazgo",
      "TOMA DE DECISIONES": "Encuentran  la opción que reporta más beneficios",
      "REGLAS Y AUTORIDAD":
        "Acomodan la norma hasta encontrar algo que los beneficie. Buscan aprobación de pares, jefes y subalternos: para ello, se adaptan con facilidad",
      "TIPO DE OBJETIVOS": "Específicos y cuantificables, relacionados con el éxito individual y de la organización",
      "TIPO DE TAREAS":
        "Prefieren ser parte de proyectos nuevos que de algo que ya existe. Tienen una fuerte orientación al trabajo y el logro de metas",
      "MANEJO DEL TIEMPO": "Utilitarismo: el tiempo es un medio limitado para usarlo. Abarca demasiado en su tiempo disponible",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": [
        "Verse en una situación de probable fracaso",
        "No hacer un papel brillante en su profesión",
        "Cargar con la culpa del mal trabajo de otros",
        "Que no se le reconozca su trabajo",
      ],
      COMPORTAMIENTO: [
        "Plantea una serie de preguntas funcionales",
        "Es improbable que diga que está disgustado",
        "Es dudoso que su lenguaje corporal dé pistas al respecto",
        "Su tono de voz se va volviendo brusco",
        "Sus frases se hacen cada vez más secas",
      ],
      "CÓMO ABORDARLO": [
        "Sea amable y claro",
        "Asegúrese de que El Competente no tiene excesiva presión laboral",
        "No utilice un tono demasiado emotivo",
        "Escoja un enfoque racional, de resolución de problemas",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Asegúrese de que el momento y el lugar son los adecuados",
        "Plantee el feedback como una manera de ayudar al receptor a tener aun más éxito",
        "Deje que el receptor sepa exactamente qué se ha dicho y quién lo ha dicho; si es posible, escoja ejemplos de clientes que respete",
        "Procure que esté de acuerdo con usted lo antes posible en los ejemplos escogidos",
      ],
      "EFECTO DE LA CONDUCTA": [
        "Refuerce el deseo de la persona de alcanzar los objetivos",
        "Subraye la diferencia entre intención y efecto",
        "Utilice la competitividad del sujeto Competente",
      ],
      "CONDUCTA DESEADA": [
        "Mantenga un tono animado en la conversación",
        "Sea concreto y práctico",
        "Haga saber al Competente que confía en él/ella para solventar la cuestión con eficacia",
      ],
    },
  },
  E: {
    shortDesc: "Intuitivo, esteta y ensimismado. Artístico, inspirado e inspirador. Sensible, empático y refinado. Afición por lo original",
    description:
      "El tipo romántico e introspectivo. Los Creativos son conscientes de sí mismos, sensibles, reservados y callados. Son demostrativos, sinceros y personales emocionalmente, pero también pueden ser algo tímidos.  En su mejor aspecto, los Creativos son muy inspirados, capaces de renovarse y transformar sus experiencias",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Se motivan con un trabajo distintivo",
        "Deben sentirse respetados por sus ideas",
        "Muy eficientes y productivos en períodos de inspiración",
        "No les favorece la competencia",
      ],
      "ESTILO DE LIDERAZGO": [
        "Creativos e innovadores",
        "Mueven profundamente al equipo, lo hacen parte",
        "Revelan sus debilidades sin dificultad",
        "Autocríticos y sensibles frente a sus seguidores",
      ],
      "TRABAJO EN EQUIPO": [
        "Busca sentido de pertenencia, aunque le gusta sentirse fuera del grupo",
        "Trabaja duro por los objetivos comunes más que personales",
        "No le gusta sentirse restringido en su individualidad",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Involucrando emocionalmente a las personas en los proyectos. Las hace parte",
      "TOMA DE DECISIONES": "Mezcla la reflexión y la imaginación",
      "REGLAS Y AUTORIDAD":
        "Puede sentir que la regla no aplica, sentirse exento. Desea estar conectado a algún líder especial, que prefiera la calidad a la popularidad",
      "TIPO DE OBJETIVOS": "Importantes y que contengan un desafío de gran alcance, con puntos de referencia concretos",
      "TIPO DE TAREAS":
        "Le gustan las tareas en las que tenga algo que aportar. Prefiere aquellos trabajos en los que se le deje crear algo significativo. Critica las cosas antes de empezar cualquier proyecto",
      "MANEJO DEL TIEMPO":
        "Muy subjetivo, según la intensidad emocional: el tiempo puede volar si están comprometidos y enfocados, o hacerse eterno, si cae en la rutina o el aburrimiento",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": [
        "Ser ignorado o desdeñado",
        "Que le pidan que haga algo contrario a sus principios",
        "Un suceso que despierte su envidia",
      ],
      COMPORTAMIENTO: [
        "Puede decir algo muy brusco",
        "También puede guardar silencio absoluto",
        "Experimenta múltiples emociones simultánea e intensamente",
        "Analiza la situación en exceso con el fin de comprenderla",
        "Sigue dolido durante mucho tiempo",
      ],
      "CÓMO ABORDARLO": [
        "Invítelo a expresar sus sentimientos",
        "Escúchelo hasta que haya terminado de hablar",
        "No dé a entender que el Creativo es susceptible",
        "Cuide de no adoptar una actitud acusatoria o inculpatoria",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Busque la compenetración con el receptor",
        "Utilice pronombres personales como yo, me y conmigo",
        "Procure no acusar al otro de nada",
        "Sea claro, directo y sincero",
        "Pregúntele por sus sentimientos y escúchelo hasta que el otro le indique que ha terminado de hablar",
      ],
      "EFECTO DE LA CONDUCTA": [
        "Haga que el receptor se sienta comprendido por usted",
        "Hable de los sentimientos",
        "Muestre interés y empatía",
      ],
      "CONDUCTA DESEADA": [
        "Haga que el receptor sienta que la decisión es suya",
        "Siga refiriéndose a los sentimientos de su interlocutor durante toda la conversación",
      ],
    },
  },
  A: {
    shortDesc: "Perceptivo, innovador y objetivo. Visionario y pionero. Excéntrico y solitario. Intelectual, culto y sabio",
    description:
      "El tipo vehemente y cerebral. Los Investigadores son despabilados, perspicaces y curiosos. Son capaces de concentrarse y enfocar la atención en desarrollar ideas y habilidades complejas. Independientes e innovadores. En su mejor aspecto, el Investigador suele estar en la vanguardia y es capaz de ver el mundo de un modo totalmente nuevo",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Energía limitada: No desean usar su tiempo en planes de otros",
        "Se esmeran para lograr privacidad, libertad y autonomía",
        "Extremadamente productivos: Prefieren trabajar solos. Se retiran para resolver las cosas",
        "Evitan estrictamente el conflicto",
      ],
      "ESTILO DE LIDERAZGO": [
        "Analíticos, prefieren pensar a actuar",
        "Entiende rápidamente los problemas y sabe cómo explicarlos a los demás",
        "Toman decisiones lógicas",
        "Paciente: aguarda y observa las cosas",
        "No necesita la aprobación de los demás",
        "Especialista: sabe más que el resto",
        "Disfruta un buen debate con ideas y argumentos sólidos",
      ],
      "TRABAJO EN EQUIPO": [
        "Prefiere trabajar solo",
        "Sabe llevar mejor las conversaciones donde hay datos e información",
        "Le gustan las reglas claras y estructuradas siempre que le dejen autonomía suficiente",
        "Prefiere las tareas individuales en áreas donde se sienta competente",
        "Le gusta ser apreciado por sus conocimientos",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Con conocimientos sólidos de fuentes fiables",
      "TOMA DE DECISIONES": "Cerebralmente, con la máxima cantidad de información posible",
      "REGLAS Y AUTORIDAD":
        "Desconfía de la autoridad e ignora lo más posible las reglas. No se deja engañar por la adulación o un liderazgo carismático",
      "TIPO DE OBJETIVOS": "Precisos, concretos, útiles y manejables",
      "TIPO DE TAREAS":
        "Prefiere las tareas que requieren investigación y análisis, hasta hacerse un experto en el campo. Aprende más con el estudio que con la práctica",
      "MANEJO DEL TIEMPO":
        "El tiempo es limitado, suele ordenar las cosas significativas cronológicamente. Tacaño con su tiempo. Lo usa para ampliar conocimientos y horizontes, observando. Lo distribuyen de modo programado y sistemático",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": ["La traición a sus confidencias", "Ser sorprendido", "La deshonestidad", "Las situaciones fuera de control"],
      COMPORTAMIENTO: [
        "Habla poco",
        "Pone distancia, pero tal vez no de forma evidente",
        "Retiene la experiencia mentalmente",
        "Tal vez el enfado adopte la forma de indignación cuando siente un malestar intenso o durante una crisis",
      ],
      "CÓMO ABORDARLO": [
        "Comuníquele de antemano que quiere hablar con él/ella",
        "Permítale elegir el momento y el lugar de la conversación",
        "Adjudique a la conversación inicial una duración determinada, de mutuo acuerdo",
        "Proporciónele abundante espacio físico",
        "Conserve una perspectiva racional del problema",
        "Mantenga una baja intensidad de sentimientos",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Establezca a priori cuánto va a durar la reunión",
        "Sea muy específico y básese en los hechos",
        "Proporcione al Investigador espacio para reflexionar sobre lo que se ha dicho",
      ],
      "EFECTO DE LA CONDUCTA": [
        "Defina lo que se va a comentar",
        "Hable con claridad",
        "Comente ideas; que sea el Investigador quien saque a relucir los sentimientos",
      ],
      "CONDUCTA DESEADA": [
        "Proporcione al Investigador tiempo a solas para reflexionar acerca de sus sentimientos, si el Investigador así lo desea",
        "Explique las razones que subyacen a las posibles acciones que usted le recomienda, para que el otro las entienda",
      ],
    },
  },
  M: {
    shortDesc:
      "Leal, encantador y responsable. Busca seguridad, prevé, se inclina hacia lo grupal. Fiel y buen amigo. Cuestionador y suspicaz",
    description:
      "El tipo leal, orientado a la seguridad. Los Comprometidos, son dignos de confianza, trabajadores y responsables. Pueden resultar evasivos, cautelosos e indecisos y también desafiantes o rebeldes.  En su mejor aspecto, las personas tipo Comprometido son estables interiormente, seguros de sí mismos, independientes, y apoyan con valentía a los débiles e incapaces",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Gran poder analítico. Examina la posición contraria",
        "Sobrevalora la autoridad. Necesita probarse frente a ella",
        "Capaces de enfrentar cualquier contratiempo",
        "Buenos mediadores. Prueban su argumento",
        "Abogados del diablo. Defienden causas menos favorecidas",
        "Tienden a restar importancia a los éxitos importantes",
      ],
      "ESTILO DE LIDERAZGO": [
        "Más esforzado y responsable que otros líderes",
        "Capaz de formar equipos sólidos",
        "Demora en tomar decisiones importantes, miedo a equivocarse",
        "Pide consejo a quien le merezca confianza. Se reasegura",
        "Muy disciplinado, sabe cómo organizarse y seguir cada detalle",
      ],
      "TRABAJO EN EQUIPO": [
        "Le gusta trabajar en equipo, funciona bien en grupos",
        "Tiene buen sentido del humor",
        "Asegura el entendimiento entre los miembros, busca participación igualitaria",
        "Mantiene a los otros miembros concentrados en tareas accesibles",
        "Actúa como cortafuegos",
        "Reconoce las contribuciones de los demás",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Siendo leal, agradable y comprometido, esperando reciprocidad",
      "TOMA DE DECISIONES": "Aunque cueste y demore, la sigue hasta el final.",
      "REGLAS Y AUTORIDAD":
        "A veces necesitan que los presionen para moverse. Se enoja cuando los demás rompen las reglas y se salen con la suya",
      "TIPO DE OBJETIVOS": "Importantes y significativos, tanto para el equipo como para los individuos",
      "TIPO DE TAREAS": "Prefiere las tareas en las que puede predecir resultados, trabajos que sean prácticos, metódicos y conocidos",
      "MANEJO DEL TIEMPO":
        "El tiempo es como una autoridad, amenazante, hay que someterse a él para responder a las demandas de otros. Trabaja urgido. Se agobia por el reloj, ya que los compromisos hay que cumplirlos conforme al horario establecido",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": ["Presión", "Falta de autenticidad", "Falta de compromiso", "Abuso de autoridad"],
      COMPORTAMIENTO: ["Puede aislarse", "Se enfrasca en un análisis intensivo", "Puede ser muy susceptible", "Hace conjeturas y planes"],
      "CÓMO ABORDARLO": [
        "Déjele espacio cuando se aísle",
        "Permítale expresarse a fondo",
        "Valide su derecho a tener su propio punto de vista",
        "Sea cálido y sincero",
        "Recupere su confianza",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Sea concreto y específico",
        "Tranquilice al otro respecto a la magnitud del problema",
        "Ofrezca sugerencias lo antes posible para que el otro prevea un resultado positivo",
      ],
      "EFECTO DE LA CONDUCTA": ["Cree un amiente de empatía", "Ayude al Comprometido a no dramatizar"],
      "CONDUCTA DESEADA": [
        "Haga saber al receptor que tiene su apoyo",
        "Asegure a la persona que sus inquietudes son normales",
        "Proporcione al receptor más de una idea para que pueda escoger entre distintas alternativas",
        "Sea consciente de que tendrá que revisar los datos varias veces con el Comprometido",
      ],
    },
  },
  G: {
    shortDesc: "Divertido, espontáneo e imaginativo. Optimista, hábil e impulsivo. Multitareas, versátil y entusiasta",
    description:
      "El tipo productivo y ajetreado. Los Planificadores son versátiles, optimistas y espontáneos; juguetones, animosos y prácticos, también podrían abarcar demasiado, ser desorganizados e indisciplinados. La actividad continuada los aturde y agota. En su mejor aspecto, el tipo Planificador centra sus dotes en objetivos dignos, son alegres, muy capacitados y agradecidos",
    workFeatures: {
      "DESEMPEÑO LABORAL": [
        "Se igualan a la autoridad con el fin de que nadie dé órdenes",
        "Prefieren las  ideas y la teoría a la implementación",
        "Se escabullen en lugar de confrontar",
        "No soportan la rutina: se conectan, sintetizan ideas y puntos de vista",
        "Proponen ideas lúcidas sin considerar su respaldo",
        "Se gana el aprecio del equipo",
      ],
      "ESTILO DE LIDERAZGO": [
        "Especialmente bueno levantado el espíritu de su equipo",
        "Se orienta hacia el futuro: es algo tempestuoso y volátil",
        "Le cuesta controlarse cuando critican su velocidad",
        "Dicen aquellas cosas que otros necesitarían nervios de acero para decir",
        "Egocéntricos, aventureros y propensos al riesgo",
      ],
      "TRABAJO EN EQUIPO": [
        "Le gusta alegrar al grupo y mantener relaciones divertidas y cordiales",
        "Prefiere el buen humor a la tragedia porque mejora el rendimiento",
        "Puede sugerir un mínimo de reglas para el grupo pero estará en contra de todas aquellas que constriñan",
        "Tiende a trabajar siguiendo sus propias prioridades y prefiere tareas variadas y roles que demanden una interacción social positiva",
      ],
    },
    extraFeatures: {
      "CÓMO INFLUYE EN EL RESTO": "Siendo encantador y divertido; elocuente y creativo para presentar las ideas",
      "TOMA DE DECISIONES": "Opta por la que tenga mayores posibilidades de éxito",
      "REGLAS Y AUTORIDAD":
        "Se siente un igual a la autoridad, así nadie le ordena y puede actuar como le plazca. Puede escabullirse de la autoridad para evitar una confrontación y evitar las reglas ampliando los términos de los acuerdos",
      "TIPO DE OBJETIVOS": "Estimulantes, inspirados y centrados en a acción",
      "TIPO DE TAREAS": "Hace muchas cosas a la vez, aunque prefieren las actividades divertidas y que sean producto de algo creativo",
      "MANEJO DEL TIEMPO":
        "El tiempo es como un bien de consumo pero ilimitado: lo usa para planificar y divertirse, orientado al futuro, con un abanico de posibilidades en mente. No se agobia por el horario, relajado, poco puntual. Disfruta imaginando el próximo proyecto y postergan lo difícil",
    },
    discomfortFeatures: {
      "CAUSAS DE MALESTAR": ["Tareas aburridas y triviales", "Sentirse rebajado o que no lo tomen en serio", "Crítica injustificada"],
      COMPORTAMIENTO: [
        "Elude la situación pensando en alternativas más agradables",
        "Racionaliza su propio comportamiento",
        "Culpa o condena a los demás",
      ],
      "CÓMO ABORDARLO": [
        "Procure no ser intrusivo",
        "Haga preguntas abiertas, sin enjuiciar",
        "Permita que El Planificador se exprese plenamente",
        "Reconstruya su línea de razonamiento",
        "Hágale saber que entiende cómo se siente",
        "Valide la experiencia del Planificador",
        "Sea sincero y directo sin caer en acusaciones",
      ],
    },
    feedback: {
      "CONDUCTA OBJETIVA": [
        "Ponga la crítica negativa entre dos comentarios positivos",
        "Deje que el Planificador sea el primero en generar ideas",
        "Utilice las ideas del receptor hasta donde sea posible",
        "Mantenga una actitud positiva",
      ],
      "EFECTO DE LA CONDUCTA": [
        "Mantenga a la persona implicada en el análisis del problema",
        "Utilice frases con el verbo en primera persona",
        "Plantee el tema en un contexto más amplio.No tenga actitud de jefe, aunque lo sea",
      ],
      "CONDUCTA DESEADA": ["Utilice el refuerzo positivo", "Permita al otro que escoja su propia opción"],
    },
  },
};
