import { http } from "@/plugins/axios";
import env from "@/config";
import axios from "axios";
import { CoreStore } from "@/store";

interface IAuth {
  email: string;
  password: string;
}
class AuthService {
  async login(data: IAuth) {
    return await http.post("/auth/local", {
      identifier: data.email,
      password: data.password,
    });
  }

  async validateJWT() {
    const jwt = localStorage.getItem("jwt");
    if (jwt)
      await axios
        .get(`${env.API_URL}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((res) => {
          // console.log(res);
          CoreStore().auth.session = res.data;
        })
        // token is invalid or has expired
        .catch(() => {
          localStorage.clear();
          window.location.reload();
        });
  }

  logout() {
    CoreStore().$reset();
    localStorage.clear();
    window.location.reload();
  }
}

export default new AuthService();
