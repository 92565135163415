<template>
  <div id="SignUp" class="bg-white rounded-xl flex max-w-screen-lg shadow-md">
    <form @submit.prevent="nextStep(!v$.$invalid)">
      <div class="p-grid p-fluid p-jc-center p-m-4">
        <div class="p-field p-col-12 p-md-6">
          <label>Nombre</label>
          <InputText id="name" v-model="v$.name.$model" type="text" placeholder="John Doe" />
          <small v-if="v$.name.$errors.length" class="p-error"> * {{ v$.name.required.$message.replace("Value", "Nombre") }} </small>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label>Email</label>
          <InputText id="email" v-model="v$.email.$model" type="email" placeholder="johndoe@mail.com" />
          <span v-if="v$.email.$errors.length">
            <template v-for="(error, index) of v$.email.$errors" :key="index">
              <small class="p-error"> * {{ error.$message.replace("Value", "Email") }} <br /> </small>
            </template>
          </span>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Edad</label>
          <InputText id="age" v-model="v$.age.$model" type="number" placeholder="18" min="18" max="100" />
          <span v-if="v$.age.$errors.length">
            <template v-for="(error, index) of v$.age.$errors" :key="index">
              <small class="p-error"> * {{ error.$message.replace("Value", "Edad") }} <br /> </small>
            </template>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label>Profesión / Oficio</label>
          <InputText id="job" v-model="v$.job.$model" type="text" placeholder="Ex: Ingenieria Industrial, Medicina, etc" />
          <small v-if="v$.job.$errors.length" class="p-error"> * {{ v$.name.required.$message.replace("Value", "Profesión") }} </small>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label>Género</label>
          <div class="flex flex-wrap gap-x-2 p-mt-2">
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.genre.$model" value="female" />
              <label>Femenino</label>
            </div>
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.genre.$model" value="male" />
              <label>Masculino</label>
            </div>
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.genre.$model" value="no_genre" />
              <label>Prefiero No Decir</label>
            </div>
          </div>
          <small v-if="v$.genre.$errors.length" class="p-error"> * {{ v$.name.required.$message.replace("Value", "Sexo") }} </small>
        </div>
        <div class="p-field p-col-12 p-md-5">
          <label>Estado Civil</label>
          <div class="flex flex-wrap gap-3 p-mt-2">
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.maritalStatus.$model" value="single" />
              <label>Soltero(a)</label>
            </div>
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.maritalStatus.$model" value="in_couple" />
              <label>En Pareja</label>
            </div>
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.maritalStatus.$model" value="married" />
              <label>Casado(a)</label>
            </div>
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.maritalStatus.$model" value="divorced" />
              <label>Divorciado(a)</label>
            </div>
            <div class="p-field-radiobutton">
              <RadioButton v-model="v$.maritalStatus.$model" value="widower" />
              <label>Viudo(a)</label>
            </div>
          </div>
          <small v-if="v$.maritalStatus.$errors.length" class="p-error">
            * {{ v$.maritalStatus.required.$message.replace("Value", "Estado Civil") }}
          </small>
        </div>

        <div class="bg-gray-200 rounded-xl flex">
          <div class="p-grid p-fluid p-m-4">
            <div class="p-field-checkbox">
              <Checkbox id="confirmTerms" v-model="v$.confirmTerms.$model" :binary="true" />
              <label><strong>He leído y acepto la Política de Privacidad</strong></label>
            </div>
            <p>
              La finalidad de recabar estos datos es generar estadísticas respecto al uso de nuestro Test de Eneagrama, y enviar
              comunicaciones por correo electrónico, que puedan ser del interés de nuestros usuarios. El destinatario de los datos
              ingresados es Dot5hosting. Tienes derecho a solicitar el acceso, rectificación y eliminación de tus datos. Puedes consultar
              más información en nuestra Política de Privacidad.
            </p>
          </div>
        </div>
        <div class="flex justify-center p-mt-4">
          <Button class="p-ml-3" label="Continuar" icon="pi pi-angle-right" iconPos="right" type="submit" :disabled="v$.$invalid" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import { CoreStore } from "@/store";
import { email, required, between, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  name: "SignUp",

  emits: ["next-step", "prev-step"],

  setup(_, ctx) {
    const store = CoreStore();

    const state = reactive({
      professional: computed(() => store.test.professionalData),
    });

    const isRequiredMsg = "Campo obligatorio";
    const rules = {
      name: { required: helpers.withMessage(isRequiredMsg, required) },
      email: {
        required: helpers.withMessage(isRequiredMsg, required),
        email: helpers.withMessage("Email inválido", email),
      },
      age: { required: helpers.withMessage(isRequiredMsg, required), between: helpers.withMessage("18 - 100", between(18, 100)) },
      genre: { required: helpers.withMessage(isRequiredMsg, required) },
      job: { required: helpers.withMessage(isRequiredMsg, required) },
      maritalStatus: { required: helpers.withMessage(isRequiredMsg, required) },
      confirmTerms: { sameAs: () => state.professional.confirmTerms === true },
    };

    const v$ = useVuelidate(rules, state.professional);

    const nextStep = async () => {
      ctx.emit("next-step", { pageIndex: 0, next: true });
    };

    return {
      v$,
      state,
      nextStep,
    };
  },
});
</script>

<style></style>
