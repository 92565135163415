<template>
  <!--Nav-->
  <nav id="header" class="fixed w-full z-30 top-0 text-white bg-blue-600 bg-opacity-90">
    <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
      <div class="pl-4 flex items-center">
        <router-link to="/">
          <div class="flex items-center text-white no-underline hover:no-underline font-bold text-xl lg:text-3xl">
            <img class="h-10 mr-2" src="/logo_new.png" alt="logo_new" />
            PERFIL EN LÍNEA
          </div>
        </router-link>
      </div>
      <div class="block lg:hidden pr-4">
        <button
          id="navMenu"
          class="flex items-center p-1 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          @click.stop="state.toggleNavbar = !state.toggleNavbar"
        >
          <svg class="fill-current text-white h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        id="navContent"
        class="w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20"
        :class="{
          hidden: !state.toggleNavbar,
          '': state.toggleNavbar,
        }"
      >
        <ul class="list-reset lg:flex justify-end flex-1 items-center" @click.stop="state.toggleNavbar = !state.toggleNavbar">
          <li class="mr-3">
            <router-link to="/">
              <a class="inline-block no-underline text-white hover:text-blue-200 hover:text-underline py-2 px-4" href="#">Inicio</a>
            </router-link>
          </li>
          <li v-if="userLogged" class="mr-3">
            <router-link to="/dashboard">
              <a class="inline-block no-underline text-white hover:text-blue-200 hover:text-underline py-2 px-4" href="#">Dashboard</a>
            </router-link>
          </li>
          <li v-if="!userLogged" class="mr-3">
            <router-link to="/login">
              <a class="inline-block no-underline text-white hover:text-blue-200 hover:text-underline py-2 px-4" href="#">Ingreso</a>
            </router-link>
          </li>
          <li v-else class="mr-3">
            <a
              class="inline-block no-underline text-white hover:text-blue-200 hover:text-underline py-2 px-4"
              href="#"
              @click.stop="logout()"
            >
              Cerrar Sesión
            </a>
          </li>
          <li>
            <router-link to="/signup" @click.stop="state.toggleNavbar = !state.toggleNavbar">
              <button
                id="navAction"
                class="inline-block mx-auto lg:mx-0 no-underline bg-white text-gray-800 font-bold rounded-full my-2 py-2 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
              >
                REALIZAR TEST
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
  </nav>
</template>
<script>
import { defineComponent, reactive, computed } from "vue";
import { CoreStore } from "@/store";
import AuthService from "@/services/auth";
export default defineComponent({
  name: "TopBar",
  setup() {
    const coreStore = CoreStore();
    const state = reactive({
      toggleNavbar: false,
    });

    const userLogged = computed(() => coreStore.auth.session);

    const logout = () => {
      AuthService.logout();
    };

    return {
      state,
      userLogged,
      logout,
    };
  },
});
</script>

<style></style>
