export const feedback = (value: string) => {
  switch (value) {
    case "strongly_agree":
      return "Muy de Acuerdo";

    case "okay":
      return "De Acuerdo";

    case "neutral":
      return "Neutro";

    case "disagree":
      return "En desacuerdo";

    case "strongly_disagree":
      return "Muy En desacuerdo";

    default:
      break;
  }
};
