<template>
  <div id="PartOne" class="bg-white rounded-xl shadow-md" data-aos="fade-right" data-aos-easing="ease-out-cubic">
    <div class="px-8 md:px-0 mb-16 rounded-xl leading-normal">
      <div class="p-grid p-fluid p-jc-center p-m-4">
        <h1 class="my-4 text-2xl leading-tight">
          Parte A: Marca todas aquellas características que te idenifiquen la mayor parte del tiempo
        </h1>
      </div>

      <div class="grid mx-4">
        <div v-for="(question, index) of partA" :key="index" class="p-1 rounded-md bg-gray-100">
          <label class="custom-check">
            {{ index + 1 }}. {{ question.name }}
            <input :id="question.id" v-model="question.value" type="checkbox" :checked="question.value" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="flex justify-center p-m-4">
        <Button class="p-mr-3" label="Volver" icon="pi pi-angle-left" @click="prevStep()" />
        <Button
          class="p-ml-3"
          label="Continuar"
          icon="pi pi-angle-right"
          iconPos="right"
          :disabled="partA.filter((q) => q.value).length >= 9 ? false : true"
          @click="nextStep()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from "vue";
import { CoreStore } from "@/store";
import { partOneData } from "./TestData";

export default defineComponent({
  name: "PartOne",

  emits: ["next-step", "prev-step"],

  setup(_, ctx) {
    const partA = ref(partOneData);

    watchEffect(() => {
      CoreStore().test.partA = partA.value;
    });

    const nextStep = () => {
      ctx.emit("next-step", { pageIndex: 1, next: true });
    };

    const prevStep = () => {
      ctx.emit("prev-step", { pageIndex: 1, next: true });
    };

    return { partA, nextStep, prevStep };
  },
});
</script>

<style style="scss" scoped>
/* layout */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 16px;
  grid-auto-flow: dense;
}

/* The container */
.custom-check {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 20px;
}

/* When the checkbox is checked, add a blue background */
input:checked ~ .checkmark {
  background-color: #1769aa;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
