export const getEnneatypeProfile = (result: any): object => {
  const findHighest = (obj: any) => {
    const values = Object.values(obj) as any;
    const max = Math.max.apply(Math, values);

    // FUNCTION TO SKIP RED ENNEATYPE IF THE MAX VALUE IS DUPLICATED
    var maxValue = Math.max.apply(null, values);
    var isUniqueMaxValue = values.lastIndexOf(maxValue) == values.indexOf(maxValue);

    if (isUniqueMaxValue) {
      for (const key in obj) {
        if (obj[key] === max) {
          return {
            [key]: max,
          };
        }
      }
    } else {
      return {};
    }
  };

  const enneatypeRed = findHighest(result.intensity) as object;
  let enneatypeBlue = {} as any;

  if (!Object.keys(enneatypeRed).length) {
    enneatypeBlue = findHighest(result.coherence);
  }

  let profile = {};

  // DEFINE THE ENNEATYPE FOR REJECTION: RED | BLUE
  switch (Object.keys(enneatypeRed).length ? Object.keys(enneatypeRed)[0] : Object.keys(enneatypeBlue)[0]) {
    case "L":
      profile = { enneatype: "L", workProfile: "Lider", loveProfile: "Protector", lifeProfile: "Intrépido" };
      break;
    case "P":
      profile = { enneatype: "P", workProfile: "Mediador", loveProfile: "Aceptador", lifeProfile: "Conciliador" };
      break;
    case "I":
      profile = { enneatype: "I", workProfile: "Perfeccionista", loveProfile: "Idealista", lifeProfile: "Reformador" };
      break;
    case "O":
      profile = { enneatype: "O", workProfile: "Coordinador", loveProfile: "Seductor", lifeProfile: "Ayudador" };
      break;
    case "V":
      profile = { enneatype: "V", workProfile: "Competente", loveProfile: "Atractivo", lifeProfile: "Competitivo" };
      break;
    case "E":
      profile = { enneatype: "E", workProfile: "Creativo", loveProfile: "Romántico", lifeProfile: "Individualista" };
      break;
    case "A":
      profile = { enneatype: "A", workProfile: "Investigador", loveProfile: "Excéntrico", lifeProfile: "Experto" };
      break;
    case "M":
      profile = { enneatype: "M", workProfile: "Comprometido", loveProfile: "Leal", lifeProfile: "Colaborador" };
      break;
    case "G":
      profile = { enneatype: "G", workProfile: "Planificador", loveProfile: "Aventurero", lifeProfile: "Generalista" };
      break;

    default:
      break;
  }
  return profile;
};
