<template>
  <div id="Test" class="pb-12">
    <div class="container px-3 mx-auto flex flex-wrap flex-col items-center mt-20 lg:mt-28">
      <router-view
        v-slot="{ Component }"
        :formData="formObject"
        @prevStep="prevStep($event)"
        @nextStep="nextStep($event)"
        @complete="complete"
      >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import SignUp from "@/components/Test/SignUp.vue";
import PartOne from "@/components/Test/PartOne.vue";

import { useRouter, useRoute } from "vue-router";
import { CoreStore } from "@/store";

export default defineComponent({
  name: "Test",

  components: {
    SignUp,
    PartOne,
  },

  setup() {
    const router = useRouter();
    const steps = ref([
      {
        label: "Registro de Usuario",
        to: "SignUp",
        show: false,
      },
      {
        label: "Parte A",
        to: "PartOne",
        show: true,
      },
      {
        label: "Parte B",
        to: "PartTwo",
        show: true,
      },
      {
        label: "Resultado",
        to: "Result",
        show: true,
      },
    ]);

    const nextStep = (event) => {
      // console.log(event);
      router.push({ name: steps.value[event.pageIndex + 1].to, params: { flag: true } });
    };
    const prevStep = (event) => {
      // console.log(event);
      router.push({ name: steps.value[event.pageIndex - 1].to, params: { flag: true } });
    };

    return { steps, nextStep, prevStep, route: useRoute(), test: CoreStore().test };
  },
});
</script>

<style lang="scss">
.p-steps .p-steps-item .p-menuitem-link {
  padding: 0 1.5em !important;
  .p-steps-number {
    background-color: #2965e8 !important;
    box-shadow: 0px 0px 5px 1px white;
  }
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  color: rgba(255, 255, 255, 0.87) !important;
}
</style>
