
import { defineComponent, ref, computed, watch } from "vue";
import { CoreStore } from "@/store";
import { workProfiles } from "@/data/WorkProfiles";
import { loveProfiles } from "@/data/LoveProfiles";
import { lifeProfiles } from "@/data/LifeProfiles";
import { IProfiles } from "@/store/models/ITest";
import "@/assets/rating.css";

export default defineComponent({
  name: "Result",

  setup() {
    const coreStore = CoreStore();

    const result = computed(() => coreStore.test.result);

    let profiles = {} as IProfiles;

    const enneatype = result.value.enneatypeProfile ? result.value.enneatypeProfile.enneatype : "L";

    switch (enneatype) {
      case "L":
        profiles = { workProfile: workProfiles.L, loveProfile: loveProfiles.L, lifeProfile: lifeProfiles.L };
        break;
      case "P":
        profiles = { workProfile: workProfiles.P, loveProfile: loveProfiles.P, lifeProfile: lifeProfiles.P };
        break;
      case "I":
        profiles = { workProfile: workProfiles.I, loveProfile: loveProfiles.I, lifeProfile: lifeProfiles.I };
        break;
      case "O":
        profiles = { workProfile: workProfiles.O, loveProfile: loveProfiles.O, lifeProfile: lifeProfiles.O };
        break;
      case "V":
        profiles = { workProfile: workProfiles.V, loveProfile: loveProfiles.V, lifeProfile: lifeProfiles.V };
        break;
      case "E":
        profiles = { workProfile: workProfiles.E, loveProfile: loveProfiles.E, lifeProfile: lifeProfiles.E };
        break;
      case "A":
        profiles = { workProfile: workProfiles.A, loveProfile: loveProfiles.A, lifeProfile: lifeProfiles.A };
        break;
      case "M":
        profiles = { workProfile: workProfiles.M, loveProfile: loveProfiles.M, lifeProfile: lifeProfiles.M };
        break;
      case "G":
        profiles = { workProfile: workProfiles.G, loveProfile: loveProfiles.G, lifeProfile: lifeProfiles.G };
        break;

      default:
        break;
    }

    const rating = ref(null);

    // RATING CHANGED UP
    watch(rating, async (value) => {
      // console.log(result.value.questionnaireId);
      await coreStore.updateTest(result.value.questionnaireId, { feedback: value });
    });

    return { result, profiles, profileLabels: result.value.enneatypeProfile || "", rating };
  },
});
