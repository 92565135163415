
import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import { CoreStore } from "@/store";
import { FilterMatchMode } from "primevue/api";
import { DateTime } from "luxon";

export default defineComponent({
  name: "Dashboard",

  setup() {
    const coreStore = CoreStore();
    const filters = ref({
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    });

    onMounted(async () => {
      await coreStore.getProfessionals();
    });

    const state = reactive({
      user: computed(() => coreStore.auth.session || ""),
      professionals: computed(() => coreStore.dashboard.professionals),
    });

    const deleteTest = async (professionalId: string) => {
      await coreStore.deleteTest(professionalId).then(async () => {
        await coreStore.getProfessionals();
      });
    };

    return { state, filters, deleteTest, DateTime };
  },
});
