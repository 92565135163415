export const loveProfiles = {
  L: {
    shortDesc:
      "Generalmente resuelto y decidido a defender a quienes ama. Suele ser proveedor y sostenedor de su pareja y familia. Tiende a ser dominante y llevado de sus ideas",
    keyWords: "Conquista, triunfo, victoria. Poder, dominio y control. Intensidad, impulsividad y protección",
    descriptions: [
      "Disfrutan de los desafíos y su fuerte personalidad los hace ser considerados como los alfa del eneagrama, pues su presencia física y psicológica va acorde con su naturaleza determinada.",
      "Son líderes naturales y fuertes que luchan por los que considera como “suyos” y no temen la confrontación.",
      "Al estar en una relación con ellos, una cercanía inesperada y un juego infantil pueden surgir en la intimidad.",
      "Debido a su naturaleza agresiva y de liderazgo, pueden parecer demasiado dominantes si no se toman el tiempo para escuchar a su pareja.",
      "Si no están en contacto con su lado vulnerable, pueden temer ser controlados por el otro y tapar sus emociones.",
      "Pueden sentirse atraídos por el conflicto y la intensidad, por lo que es importante conocerlos a su nivel y dejarlos defenderse por sí mismos.",
      "Suelen tener problemas para conectarse con su corazón, por lo que valorarán a las parejas que pueden crear un espacio seguro para que puedan acceder a sus emociones y utilizar una comunicación directa",
    ],
    behaviors: {
      Conquista:
        "Generalmente seguro y arrojado; resuelto a obtener su objetivo. Suele ser activo y entusiasta en el proceso de cortejo. Puede perder el interés con la rutina o cuando ya conoce la dinámica del otro",
      Compromiso:
        "Suele ser posesivo y territorial: defenderá a su pareja ante cualquier evento o cualquier persona. Lo que considera “suyo” es intocable",
      Comunicación:
        "Generalmente directo: brutalmente franco. Suele utilizar un potente lenguaje corporal. No se anda con rodeos. Puede parecer Confrontacional, y en algunos casos, fanfarrón. Tiende a impacientarse o a perder el control",
      Afectividad:
        "El amor verdadero puede hacer despertar su ternura y mostrar su vulnerabilidad; aunque esto no sucede frecuentemente, sólo se entrega si está seguro de que puede ceder el control",
      Conflicto:
        "Puede ir con todo a una pelea, creerá siempre llevar la razón. Frecuentemente, la intensidad de un buen conflicto lo revitaliza",
      Reconciliación: "Una vez descargada su ira y agresividad, suele reponerse fácilmente",
    },
    sexuality:
      "Tiende a la hipersexualidad, otorgándole gran importancia al sexo. Alta probabilidad de éxito en sus relaciones sexuales. Suele ser muy seguro y animado respecto al tema. Tiende a separar el sexo del romance o el amor",
    features: [
      "Generalmente es un amante impetuoso, generoso, concreto, intrépido, que va por lo que quiere.",
      "Si tiene a alguien en su mira, perseverará en la conquista con emoción y objetivos claros.",
      "Usualmente no le teme al rechazo.",
      "Se gana el amor del objeto de su deseo con autoconfianza y su personalidad atrayente.",
      "Disfruta el proceso de conquista y el desafío es concretar el acto sexual.",
      "Su marcada tendencia al control en la vida, puede cederla en la intimidad.",
      "Pone mucho énfasis en la forma de hacer el amor: ímpetu e intensidad. Disfruta con una pareja que le siga el ritmo",
    ],
    state: {
      "ESTADO ESTRÉS":
        "Su energía y desparpajo sexual pueden alejar a su pareja. Cuanto más determinado esté en ir en dicha dirección, su compañero más tenderá a apartarse",
      "ESTADO LIBERACIÓN":
        "Se dan cuenta de la dinámica anterior, por lo que intentan integrar la delicadeza y amabilidad a la hora de amar",
    },
  },
  P: {
    shortDesc:
      "Generalmente comprensivo y bondadoso. Tiende a la comodidad. Prefiere ceder ante su pareja en vez de pelear. Puede parecer despreocupado o indolente. Suele optar por las rutinas conocidas en la relación",
    keyWords: "Comodidad, tranquilidad, estabilidad. Fusión y adaptación",
    descriptions: [
      "Son capaces de ver todas las perspectivas en una situación.",
      "Tienen un aura cálida y armoniosa, que se atribuye a su naturaleza feliz y despreocupada.",
      "Son extraordinariamente pacientes, y sus parejas se sentirán profundamente reconocidas en la relación.",
      "Se preocupan por mantener la paz interna/externa y suavizar la tensión a través de la empatía hacia su pareja.",
      "Pueden ser demasiado complacientes, lo que puede conducir a la inercia en muchos aspectos de su vida, principalmente al abordar conflictos o tomar ciertas decisiones respecto a la relación.",
      "Debido a que van con la corriente, pueden deslizarse en piloto automático con sus hábitos y moverse por la vida sin mucho impacto en su entorno o en la vida de sus seres queridos.",
      "Si se resienten por no hablar, pueden volverse pasivo-agresivos.",
      "Necesitan una pareja que los anime a dar un paso adelante en su propia vida y les ayude a encontrar su voz para que puedan expresar sus opiniones.",
    ],
    behaviors: {
      Conquista:
        "Generalmente, con aceptación hacia el otro y positividad. Orientado a la Idealización. Frecuentemente muy empático. Suele hacer sentir seguro a su objeto de cortejo, probablemente sin darse cuenta de aquello. Tiende a ser una persona nada amenazante, más bien cálida y acogedora.",
      Compromiso: "Su implicación suele ser total; se fusiona con su pareja, la acepta tal como es y respalda ante todo evento",
      Comunicación:
        "Generalmente ecuánime, pendiente del punto de vista del otro. Usualmente prefiere acentuar los aspectos positivos de la relación en vez de insistir en lo negativo. Tiende a “llevar la fiesta en paz",
      Afectividad: "No suele ser muy expresivo, aunque puede ser cariñoso y romántico en un contexto estable y relajado.",
      Conflicto:
        "Tiende a evitar enojarse, por lo que puede transar sus propias ideas en favor del otro. Posible resistencia pasivo-agresiva, que podría traducirse en tozudez. ",
      Reconciliación:
        "Postura firme si finalmente ha tomado alguna decisión con respecto a la relación. Si se trata de un reencuentro, lo hará de corazón. Si no lo es, permanecerá intransigente en el quiebre",
    },
    sexuality:
      "Generalmente, entregado a la satisfacción y al placer. No suele tomar la iniciativa, pero no teme a experimentar lo que su pareja le proponga",
    features: [
      "Amante dulce y comedido, deferente hacia su pareja y la relación.",
      "Puede necesitar un empujón de su pareja para modificar algún hábito sexual que dé resultados, sin embargo, estará abierto a explorar cualquier alternativa que su pareja le proponga debido a su anhelo por complacerla.",
      "Probablemente no sea del todo consciente de cuán sensual y atractivo resulta para su pareja",
    ],
    state: {
      "ESTADO ESTRÉS":
        "La excesiva complacencia hacia el otro puede hacer que disminuya la sintonía con sus propios deseos y necesidades. Su pareja podría sentir falta de realidad en una sexualidad en que todo marcha empalagosamente feliz, como miel sobre hojuelas. Eventualmente habrá que sacarlo de sus actividades mundanas para provocar un encuentro sexual que sea más atractivo que la TV o el videojuego",
      "ESTADO ESTRÉS	ESTADO LIBERACIÓN":
        "Se logra conectar con su deseo propio y es capaz de expresarlo. Manifiesta sus necesidades individuales y las considera con la misma importancia que las de su pareja. Arriesga más a la hora de experimentar",
    },
  },
  I: {
    shortDesc:
      "Frecuentemente hace lo “que debe hacer” por su pareja. Generalmente es determinado y noble. Suelen importarle mucho los detalles y formas. Tiende a apegarse a la norma social. Frecuentemente se muestra serio y poco espontáneo. Puede dominarlo el deber y postergar el placer. Suele presentar rigidez en sus posturas.",
    keyWords: "Perfección, delicadeza, excelencia. Mejoramiento. Integridad, moralidad, honor. Escrúpulo y ética",
    descriptions: [
      "Serio y arraigado en el pragmatismo y la integridad propia y de su pareja.",
      "Fuerte carácter moral y compromiso con la superación personal y mejora de la relación.",
      "Apasionado, disciplinado y estructurado; apreciará a alguien que también respalde sus palabras con hechos.",
      "Tiene un duro crítico interno y una fuerte necesidad de tener la razón, lo cual llega a proyectar sobre su pareja.",
      "Suele asumir demasiadas responsabilidades, además de que busca la perfección y eso le dificulta relajarse.",
      "Ansioso y culpable por tomarse un descanso, lo que puede manifestarse como más crítica y autoculpa.",
    ],
    behaviors: {
      Conquista:
        "Tiende a la seriedad y sobriedad. Encaminado a la estabilidad y su entrega al compromiso. Sus principios y valores pueden constituir sus armas de seducción.",
      Compromiso:
        "Suele estar altamente comprometido en una relación que se ajuste a sus criterios del “deber ser”. La fidelidad es un valor que tiende a practicar y exigir. Aspira a admirar a su pareja para afianzar la relación.",
      Comunicación:
        "Suele ser seca y precisa con detalles claros. Pueden dejar entrever su frustración permanentemente por todo aquello que no está funcionando a la perfección o no llena sus expectativas.",
      Afectividad:
        "Frecuentemente, bajo nivel de demostración de sus sentimientos. No suele ser muy cariñoso, pero expresa sus más profundos afectos a través de actos concretos. Pragmatismo.",
      Conflicto:
        "Puede racionalizar y culpar al otro por lo que va mal en la relación. También puede montar en cólera y dejar salir su ira. O puede guardar silencio absoluto. Tozudez.",
      Reconciliación:
        "Una vez que ha soltado su enojo, puede reponerse fácilmente. Tal vez guarde resentimiento si no ha logrado expresar todo lo que siente.",
    },
    sexuality:
      "Alto impulso sexual que tienden a reprimir. Pulcritud. Culpa. Le cuesta soltarse y entregarse al placer. Necesita que las condiciones estén dadas para mejorar su desempeño. Rigidez física y emocional.",
    features: [
      "Profundos deseos y anhelos sexuales que, a veces se contraponen con una moralidad exacerbada.",
      "Le cuesta relajarse y dejarse llevar por sus fuertes instintos sensoriales.",
      "Necesita de una ambientación perfecta para un apropiado funcionamiento sexual: detalles magistralmente organizados.",
      "Como maestro, también en el amor, tratará de mejorar la vida sexual y amorosa de su pareja, para que pueda aprender de sus propios gustos y preferencias e incorporarlos a la relación con el objetivo de un progreso sustantivo en la sexualidad de la pareja",
    ],
    state: {
      "ESTADO ESTRÉS":
        "En tanto existan detalles incorrectos, que lo sacan de su cuerpo y lo llevan a un estado mental de juicio, no logrará relajarse del todo, incluso puede cuestionarse por su propio desempeño, el de su amante, el desarrollo de la relación sexual",
      "ESTADO LIBERACIÓN":
        "Cuando deja de juzgar si él, su pareja o la relación están bien o mal. Deja de lado la necesidad de calificar y empieza a disfrutar. En ese momento, puede liberar su sexualidad y también liberar a su pareja, haciendo del sexo un proceso fluido y fruto del amor",
    },
  },
  O: {
    shortDesc:
      "Suele ser complaciente y posesivo. Generalmente, ayuda al otro en la consecución de sus objetivos, a veces olvidando los propios. Puede decepcionarse fácilmente si no recibe lo que espera, por lo que podría recurrir a la manipulación.",
    keyWords: "Expresión de amor y cariño. Necesidad, ser indispensable. Sacrificio. Victimización, martirización.",
    descriptions: [
      "Agradables, optimistas y positivos; es fácil que puedas ser tú mismo al estar a su lado.",
      "Tienen una actitud sin prejuicios que hace que te sientas bienvenido y abrazado.",
      "Extremadamente cariñosos y compasivos, por lo que buscan apoyar y elevar a las personas que les importan.",
      "Suelen estar naturalmente sincronizados con lo que su pareja quiere y necesita.",
      "Pueden saber lo que quiere su pareja, pero no saben lo que ellos mismos quieren.",
      "Les resulta difícil expresar sus necesidades, por lo que ofrecen su ayuda con expectativas implícitas de que su pareja les devuelva el favor.",
      "Tienen la tendencia a volverse excesivamente dependientes, por lo que su pareja tendrá que recordarles que deben atender sus propias necesidades y dirigir su atención hacia ellos mismos, no sólo hacia los demás",
    ],
    behaviors: {
      Conquista:
        "Suele ser el seductor por excelencia, transita por la vida al pendiente de sus posibles prospectos. Generalmente sabe detectar las necesidades del otro y cómo satisfacerlas",
      Compromiso:
        "Tiende a implicarse por completo, siempre que obtenga muchos gestos de cariño de su pareja. Si está desatendido emocional o sexualmente puede rebelarse generando conflicto e incluso ser infiel",
      Comunicación:
        "Trata de agradar permanentemente, por lo que gesticula graciosa y fluidamente. Se olvida o no repara en el fondo de los problemas, cayendo en superficialidades. Guarda sus sentimientos hasta que logra expresarlos en una explosión emocional",
      Afectividad:
        "Busca complacer al otro, colmándolo de atenciones, cuidados y halagos. Muchos besos, abrazos, caricias y demostraciones de cariño y apoyo. Absorbente y demandante",
      Conflicto:
        "Puede estallar emocionalmente, sacar en cara y pasar la factura por haberlo entregado todo y no recibir lo que esperaba a cambio. Para él, su divisa es el amor y le enfurece no obtenerla",
      Reconciliación:
        "Una gran muestra de afecto o una intensa sesión de sexo, puede hacer que el seductor olvide el conflicto inicial y siga con la relación",
    },
    sexuality:
      "Hará todo lo que sea para llamar la atención del otro y lograr su aprobación, siempre que de vuelta se sienta querido. Para él, el sexo es igual a amor. Generalmente reprime lo que a él le gustaría en favor de complacer a su pareja",
    features: [
      "El enamorado del amor: su fin último es estar involucrado en una relación.",
      "Se cuestiona si es digno de ser amado, por lo que seduce, seduce y vuelve a seducir, a todos, todo el tiempo.",
      "En la superficie, es un dador bondadoso y amoroso que busca desesperadamente cariño.",
      "Realiza todo tipo de cosas para conquistar a su amante con el objetivo de que este disfrute. En el intertanto, olvida sus propios deseos y necesidades.",
      "En la búsqueda de ese orgasmo mágico de su pareja, puede olvidarse de los suyos propios; hasta que se aburra de esa rutina y comience a exigir.",
      "Por el lado de la pareja, al darse cuenta de todo lo que recibe, queda siempre al debe emocionalmente con su tipo Seductor",
    ],
    state: {
      "ESTADO ESTRÉS":
        "El sexo se convierte en una acción apasionada que conlleva intenciones ocultas. Es un alimento para satisfacer a su pareja, con la expectativa de una retribución posterior",
      "ESTADO LIBERACIÓN":
        "El Seductor aprende a dejar ir el control subyacente a la entrega continua. Acepta el cariño que su pareja intenta darle. Deja de lado el orgullo de creerse exento de necesidades y deseos propios",
    },
  },
  V: {
    shortDesc:
      "Suele ser altamente pragmático. Tiende a adaptar sus gustos y preferencias a las de su pareja, siempre que no interfieran con su imagen hacia el exterior. Puede caer en un espiral de competencia con el otro, aunque trate de adaptarse camaleónicamente a sus expectativas",
    keyWords: "Imagen. Atracción física y social. Beneficio, recompensa, gratificación.",
    descriptions: [
      "Son encantadores y seguros de sí mismos.",
      "Encarnan las cualidades tradicionales del éxito y atractivo personal según la cultura en la que se desenvuelvan.",
      "Su increíble capacidad para hacer que las cosas sucedan puede resultar muy atractiva.",
      "Las personas se sienten naturalmente atraídas por su carisma natural y su gran confianza en sí mismos.",
      "En las relaciones amorosas también aplican su ilimitado entusiasmo y creatividad.",
      "Al estar tan enfocados en el rendimiento y la imagen, pueden desconectarse de sus reales sentimientos y emociones.",
      "Ya que están muy enfocados en el trabajo, es importante apoyarlos en la creación de un equilibrio saludable entre la vida laboral y personal, que incluya descanso y procesamiento emocional.",
      "Precisamente esa sed de éxito puede llevarlos a hacer que su pareja se sienta excluida emocionalmente, por lo que, para reconectar con ellos, es importante mostrarles un amor incondicional por lo que son, no por lo que hacen",
    ],
    behaviors: {
      Conquista:
        "Generalmente muestra su mejor imagen y encubre sus inseguridades. El otro nunca sabrá sus reales emociones. Suele ser cortés y amigable. Tiende a adaptarse a las expectativas del otro y puede superarlas con creces. Puede usar su atractivo físico como recurso de cortejo. Frecuentemente hace resaltar todos sus atributos",
      Compromiso: "Usualmente se compromete con el objetivo de lograr una relación funcional y exitosa, más que con la otra persona",
      Comunicación:
        "No suele mostrar sus sentimientos reales. Si se siente inseguro, puede ser reservado y frío. Tiende a conservar las formas apropiadas y no perder el control",
      Afectividad:
        "Frecuentemente se aleja de los sentimientos. Puede ser muy intenso y luego desligarse. Pragmático, aunque puede ser muy cariñoso si persigue un objetivo",
      Conflicto:
        "Puede resultar muy difícil llegar a él, pues suele negar sus emociones. Generalmente se ofusca si no logra su objetivo o si algo interfiere en el logro de su cometido",
      Reconciliación:
        "Se esfuerza para que las cosas mejoren, cambiando su curso de acción, con el objetivo de lograr la reconciliación si es que la desean",
    },
    sexuality:
      "Puede dejar de percibir placer por estar pendiente de su imagen. Busca gustar. Es como si el otro le devolviera su reflejo a través del sexo, aprobando su desempeño. Es difícil que se entreguen por completo",
    features: [
      "Puede haber un deseo de brillar, de ser una estrella en el dormitorio. Generalmente le fascina resplandecer para su amante y que éste lo admire.",
      "El reconocimiento a sus talentos amatorios y a su potencial ilimitado suele ser muy importante para el tipo Atractivo.",
      "Pueden considerar sus hazañas sexuales como otra forma de ganar aplausos, por lo que el sexo podría convertirse en una actuación más que en genuino e íntimo placer",
    ],
    state: {
      "ESTADO ESTRÉS":
        "El sexo podría llegar a ser tortuoso si el desempeño logrado no es el que el tipo Atractivo esperaba tener. Algunos podrían refugiarse en el trabajo como forma de evitar los encuentros.",
      "ESTADO LIBERACIÓN":
        "El tipo Atractivo acepta a su pareja y a sí mismo auténtica y completamente, termina con la necesidad de lograr ciertas metas sexuales. Es capaz de vivir la sexualidad como un proceso de entrega y recepción, centrado en los sentimientos y en amor",
    },
  },
  E: {
    shortDesc:
      "Generalmente sensible y expresivo. Le gusta compartir momentos únicos e intensos con su pareja. Suele temerle al abandono, por lo que protege su individualidad. Presenta algún grado de aversión a la rutina. Tiende a ser creativo y original dentro de una relación de confianza",
    keyWords: "Idealismo romántico. Intensidad emocional. Originalidad.",
    descriptions: [
      "Tienen una inclinación hacia lo artístico y aportan un toque original e innovador a la relación.",
      "Suelen ser honestos y no tener miedo de enfrentar sus emociones, sin importar cuán incómodo u oscuro sea el sentimiento.",
      "Honran el espectro emocional completo y, como resultado, no tienen problemas para sumergirse en temas vulnerables. A su pareja podría resultarle liberador estar con alguien emocionalmente fuera de lo común.",
      "Dado que están más en contacto con el dolor y el sufrimiento, pueden comenzar a vincularse demasiado con sentimientos dolorosos y convencerse de que son defectuosos de alguna manera porque se sienten diferentes.",
      "También pueden acabar envueltos en relaciones tóxicas, ya que frecuentemente tienen miedo al abandono y al rechazo.",
      "Debido a que sus estados de ánimo fluctúan con frecuencia y pueden dejarse llevar por sus sentimientos, su pareja debe esforzarse para ser su ancla y mantener los pies en la tierra.",
    ],
    behaviors: {
      Conquista:
        "Puede resultar misterioso y sensual. Su fragilidad tiende a despertar la atracción del otro. Suele ser inseguro. Tendencia a la idealización de su objeto de deseo. Frecuentemente no teme mostrar sus aspectos más negativos en esta fase, como poniendo a prueba al otro.",
      Compromiso:
        "No tiende a ser muy propenso al compromiso. Su temor al abandono lo hace entrar en un espiral de tira y afloja permanentemente. Suele buscar admiración por la otra persona",
      Comunicación:
        "Generalmente expresivo y con su sensibilidad a flor de piel. Aunque trate de contenerse suele dejar entrever su emoción. Puede ser hostil. Orientado a comunicarse intensamente desde sus sentimientos",
      Afectividad:
        "Frecuentemente romántico y afectuoso, suele salir con cosas fuera de lo común para demostrar sus afectos y mantener encendida la relación. La rutina y lo común tiende a debilitar sus pasiones. La falta de intensidad del otro puede hacer disminuir su interés",
      Conflicto:
        "Podría reaccionar de forma emotiva cuando se siente rechazado o menospreciado. También podría guardar resentimiento. La emoción suele persistir por un largo tiempo",
      Reconciliación: "Su interés en reconciliarse acostumbra a despertar en el momento en que la pareja se aleja, anhelo por lo perdido",
    },
    sexuality: "Inhibido por sus propias inseguridades. Una relación de confianza y aceptación despierta su creatividad",
    features: [
      "El amor es su lenguaje: bien ejecutado, impulsivo y destemplado.",
      "Anhela una pareja que lo comprenda y con la que logre una intimidad total: intensa y veraz. Debido a que sus expectativas son difíciles de cumplir, tiende a sentirse solitario y postergado.",
      "Pareciera que todas las demás parejas fueran felices y disfrutaran de relaciones satisfactorias y al Romántico el amor le fuera algo esquivo",
    ],
    state: {
      "ESTADO ESTRÉS":
        "El Romántico tiende a caer en la melancolía o profunda nostalgia por lo que podría haber sido, pero que no es en el presente",
      "ESTADO LIBERACIÓN":
        "En las relaciones íntimas, el Romántico puede estar profundamente acoplado con su pareja. El amor se descubre y se manifiesta. Se conecta con la realidad actual y se desapega de la fantasía",
    },
  },
  A: {
    shortDesc:
      "Generalmente autónomo y autosuficiente. No suelen gustarle las demandas afectivas ni que dependan de él; aunque puede ser muy romántico con su pareja, necesita de su espacio. Tiende a ser reservado y algo aislado.",
    keyWords: "Independencia y privacidad. Moderación emocional. Poco ímpetu sentimental",
    descriptions: [
      "Suelen ser inquisitivos, quieren entender el mundo y a su pareja.",
      "Desean asegurarse de tener la información y las habilidades para hacer lo que quieren y necesitan.",
      "Tienden a pensar las cosas mucho tiempo antes de poder pasar a la acción.",
      "Tienen una vida mental vívida y son muy perceptivos.",
      "Se toman el tiempo de aprender y sintetizar información objetivamente, lo que los convierte en excelentes oyentes y asesores de sus parejas.",
      "Orientados a la razón, por lo que pueden parecer un poco alejados de sus emociones.",
      "Su motivación para el conocimiento puede acaparar su tiempo cuando están demasiado preocupados por sus intereses. Suelen sentir que nunca tienen suficiente tiempo o energía, así que es importante saber respetar su tiempo a solas.",
      "Este tipo de personalidad suele apreciar a una pareja que le da espacio para descubrir cómo se siente",
    ],
    behaviors: {
      Conquista:
        "Tiende a ser muy reservado y algo excéntrico en esta fase. Puede deslumbrar al otro con su sapiencia en algunos campos. Suele conquistar con su inteligencia, sagacidad y vehemencia",
      Compromiso:
        "Compromiso profundo y sentido, aunque no expresivo. Busca lealtad y confianza total, si las encuentra, su nivel de implicancia es muy alto",
      Comunicación:
        "Generalmente de baja intensidad. En completa confianza puede ser argumentativo y vehemente. Tendencia a guardar secretos",
      Afectividad:
        "Suelen tener profundos sentimientos que poco demuestran. Tiende a presentar baja expresión de los afectos, pero puede llegar a ser bastante romántico en una relación de confianza.",
      Conflicto:
        "Puede enmudecer y distanciarse por largos períodos. Podría mostrar su tozudez en medio de una pelea o cuando está molesto",
      Reconciliación:
        "Frecuentemente necesita tiempo y espacio para entender sus sentimientos. Una vez procesados, suele volver sin rencor.",
    },
    sexuality:
      "Tiende a presentar apertura de mente, no teme experimentar. Puede existir cierta desconexión de los sentimientos, por lo que da espacio para que el sexo sea un encuentro principalmente físico",
    features: [
      "Puede desear una conexión profunda con una pareja, pero teme que la relación le requiera sacrificar demasiado tiempo. Por lo que el vínculo suele ser deseable, aunque podría provocar ciertos conflictos internos.",
      "La sexualidad tiende a ser un proceso que debe ser observado e investigado; un lugar para la cabeza y un espacio para el cuerpo que el otro desea, dejando algo de lado los sentimientos.",
      "Para algunos Excéntricos, la expresión más frecuente frente a una relación es arrinconarse en su espacio individual donde se sienta más seguro y pueda encausar sus emociones.",
      "Entonces, podría funcionar una relación a distancia, un trabajo que implique viajar, una pareja independiente o permanecer soltero",
    ],
    state: {
      "ESTADO ESTRÉS":
        "El tipo Excéntrico podría perderse la posibilidad de una relación de pareja compenetrada y segura, en búsqueda de una relación poco invasiva, que es menos problemática.",
      "ESTADO LIBERACIÓN":
        "Como es de mente abierta, no tiene problemas en experimentar la sexualidad en sus diversas formas. Logra conectar los tres centros en la relación; de forma sensorial, sin censura y compartiendo sus emociones con una pareja de confianza",
    },
  },
  M: {
    shortDesc:
      "Suele buscar seguridad, por lo que es leal y exige compromiso. Generalmente prudente y temeroso, tiende a ser conservador en la relación para evitar riesgos. Su confianza podría ganarse a través de un patrón de conducta previsible",
    keyWords: "Seguridad, lealtad y fidelidad. Miedo al rechazo",
    descriptions: [
      "Son responsables, confiables, atentos y muy leales a sus creencias y a su pareja.",
      "Son personas con quienes se puede contar firmemente.",
      "Los motiva la seguridad y se preocupan profundamente por nutrir sus conexiones con sus seres queridos.",
      "Pueden tener una presencia cálida que se siente como un puerto seguro para que sus parejas regresen a sus hogares.",
      "A veces son leales porque temen ser abandonados y suelen preocuparse por los problemas que puedan surgir en el futuro.",
      "Pueden ser escépticos, reacios al riesgo e inseguros, y necesitan consuelo constante, ya que depositan su fe en el entorno y en los demás, en lugar de hacerlo en sí mismos.",
      "Como pareja, es importante mantenerse estable ante sus temores y preocupaciones para que sepan que cuentan con tu apoyo.",
      "Aprecian a las parejas que escuchan sus pensamientos ansiosos y los alientan a encontrar su propia guía interior",
    ],
    behaviors: {
      Conquista:
        "Generalmente encantador. Mezcla de reparo y simpatía, buen conversador. Suele ser agradable y frecuentemente atractivo. Puede ir abiertamente por lo que quiere, aunque también puede ceder ante el miedo de lograrlo",
      Compromiso:
        "Suele sentirse seguro en una relación estable, por lo que es leal, fiel y comprometido. Duda de él y del cariño del otro",
      Comunicación:
        "Suele expresarse con rodeos y de forma ambigua con el fin de no herir los sentimientos del otro. Puede también ser estricto o desafiante en sus modos",
      Afectividad:
        "Generalmente tierno y cariñoso. Tiende a expresar sus afectos física y emocionalmente. Puede quitar el cariño si se siente amenazado, con miedo o desconfianza",
      Conflicto:
        "Trata de evitar el conflicto. Frecuentemente proyecta sus emociones en el otro, lo hace objeto de su desconfianza. Puede pedir consejo externo.",
      Reconciliación:
        "Si bien le cuesta terminar la relación, una vez roto el compromiso o quebradas las confianzas, le cuesta volver a entregarse a su pareja",
    },
    sexuality:
      "El sexo podría ser secundario. Algo tradicional y retraído. Puede sentir culpa por experimentar placer y, en algunos casos, le cuesta tomar la iniciativa. Tiende a mostrar ansiedad. Puede ser tierno y apasionado",
    features: [
      "A pesar de todos los atributos y cualidades que posee, puede cuestionarse si su pareja realmente lo ama. La duda y la incertidumbre pueden mermar su autoestima y hacer que se pregunte si puede realmente confiar.",
      "El sexo podría traer problemas. Agobiado por la preocupación constante, el goce espontáneo de la sexualidad podría verse disminuido. Como si prever los peores escenarios sexuales posibles pudieran arreglarse con el solo hecho de advertirlos previamente",
    ],
    state: {
      "ESTADO ESTRÉS":
        "Puede perder el disfrute del momento; postura conservadora o tradicional; se cuestiona todos los potenciales problemas de la relación sexual",
      "ESTADO LIBERACIÓN":
        "Se da cuenta que sus preocupaciones son proyecciones infundadas, se empieza a conocer, se convierte en su propia autoridad, se relaja y se abre a experimentar. Se vuelve completamente presente para su pareja",
    },
  },
  G: {
    shortDesc:
      "Generalmente, es un inquieto en busca del placer. Suelen no gustarle los límites y agobiarse con el compromiso. Orientado a la diversidad. Tiende a mantener la llama encendida para no aburrirse ni inclinarse a la infidelidad",
    keyWords: "Amor idealizado. Estimulante. Espontaneidad y dinamismo. Sin límites",
    descriptions: [
      "Tienden a ser brillantes, aventureros y a estar siempre en movimiento; tienen una energía magnética e irresistible para sus prospectos de pareja.",
      "Su curiosidad los convierte en personas multifacéticas y animadas.",
      "Son compañeros divertidos, que impulsan a sus parejas a salir de su zona de confort, además de que saben encontrar la forma de disfrutar de cada situación.",
      "Debido a su mente rápida y su capacidad de adaptación, siempre están listos para probar cosas nuevas.",
      "Generalmente huyen de posibles emociones negativas, por lo que buscan distracción para evitar estar presentes y estáticos, lo cual los hace impulsivos e inquietos.",
      "Debido a que están en una constante búsqueda de lo mejor, pueden sentirse claustrofóbicos en una relación y tener problemas para comprometerse.",
      "Generalmente no les va bien con el apego, por lo que necesitan una pareja independiente, que les dé su espacio y sepa tener una vida plena en lo individual.",
      "Su pareja deberá enseñarles a explorar sus emociones reprimidas y reducir la velocidad para disfrutar de la vida a un ritmo más lento",
    ],
    behaviors: {
      Conquista: "Tiende a mantener diversas opciones abiertas. Suele cortejar activamente cuando alguien realmente le interesa",
      Compromiso:
        "Frecuentemente le cuesta comprometerse y ser fiel, aunque podría implicarse de corazón. Cuando termina una relación, frecuentemente pasa fácilmente a otra, como un proceso más de aprendizaje",
      Comunicación:
        "Generalmente ágil, escurridizo y disperso. Suele eludir sus responsabilidades. Tiende a ser espontáneo y pensar poco antes de hablar. Puede resultar hiriente si está ofuscado, aunque generalmente es muy alegre y jovial",
      Afectividad:
        "Usualmente expresa su afectividad mediante la diversión y el entretenimiento, inventando panoramas en pareja. Suelen ser cariñosos y expresivos cuando se sienten enamorados",
      Conflicto:
        "Tiende a culpar al otro por los problemas. Puede ser enérgico e hiriente en medio de una pelea, pero su enojo suele durar poco",
      Reconciliación: "Se reconcilia fácilmente y sin rencor; olvida el problema y sigue con el siguiente plan",
    },
    sexuality:
      "Generalmente creativo y juguetón. Tiende a disfrutar una amplia vida sexual con diversas parejas sexuales. Podría caer en la promiscuidad. Le gusta experimentar nuevas sensaciones",
    keyFeature: {
      "EL AVENTURERO ESPONTÁNEO": [
        "Busca disfrutar del placer en la mayor cantidad de formas posibles y sexualmente es un tentador abanico de opciones.",
        "Sexo vivificante para matar el aburrimiento de la vida cotidiana, lo que no significa necesariamente que sea infiel.",
        "La rutina sexual no le excita, más bien le puede resultar molesta",
      ],
    },
    features: [
      "Tiende a disfrutar del placer en la mayor cantidad de formas posibles y sexualmente suele ser un tentador abanico de posibilidades.",
      "Frecuentemente buscará sexo vivificante para matar el aburrimiento de la vida cotidiana, lo que no significa necesariamente que sea infiel.",
      "Generalmente la rutina sexual no le excita, más bien le puede resultar molesta",
    ],
    state: {
      "ESTADO ESTRÉS":
        "Descorazonado con un amante habitual, puede entrar a preguntarse cómo sería aquella opción que le es prohibitiva. Lo que redunda en que pocas veces disfruta del sexo que está teniendo en el presente. Aunque lo considera bueno, en su mente fantasea con que otra opción sería aún mejor",
      "ESTADO LIBERACIÓN":
        "Aprende a disfrutar el sexo presente y de la mano de su pareja; dejando de fantasear con uno diferente en el futuro. Valora y exalta la sexualidad que está desarrollando en el aquí y ahora, aportando con su espontaneidad a la relación",
    },
  },
};
