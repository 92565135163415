export const calculateEnneatypes = (answers: Array<Object>, part: string) => {
  let enneatypeL = 0;
  let enneatypeP = 0;
  let enneatypeI = 0;
  let enneatypeO = 0;
  let enneatypeV = 0;
  let enneatypeE = 0;
  let enneatypeA = 0;
  let enneatypeM = 0;
  let enneatypeG = 0;
  let LT = 0,
    LM = 0,
    LD = 0,
    PT = 0,
    PM = 0,
    PD = 0,
    IT = 0,
    IM = 0,
    ID = 0,
    OT = 0,
    OM = 0,
    OD = 0,
    VT = 0,
    VM = 0,
    VD = 0,
    ET = 0,
    EM = 0,
    ED = 0,
    AT = 0,
    AM = 0,
    AD = 0,
    MT = 0,
    MM = 0,
    MD = 0,
    GT = 0,
    GM = 0,
    GD = 0;
  let enneatypes: any = [];

  if (answers.length) {
    enneatypes = answers.reduce((acc: any, curr: any) => {
      const _part = part === "A" ? curr.enneatype : curr.options.find((o: any) => o.value).enneatype;
      const _value = part === "A" ? curr.value : curr.options.find((o: any) => o.value).value;
      if (_value) {
        switch (_part) {
          case "L":
            enneatypeL++;
            break;
          case "P":
            enneatypeP++;
            break;
          case "I":
            enneatypeI++;
            break;
          case "O":
            enneatypeO++;
            break;
          case "V":
            enneatypeV++;
            break;
          case "E":
            enneatypeE++;
            break;
          case "A":
            enneatypeA++;
            break;
          case "M":
            enneatypeM++;
            break;
          case "G":
            enneatypeG++;
            break;

          default:
            break;
        }

        if (part === "A") {
          switch ([_part, curr.category].join(",")) {
            case "L,T":
              LT++;
              break;
            case "L,M":
              LM++;
              break;
            case "L,D":
              LD++;
              break;
            case "P,T":
              PT++;
              break;
            case "P,M":
              PM++;
              break;
            case "P,D":
              PD++;
              break;
            case "I,T":
              IT++;
              break;
            case "I,M":
              IM++;
              break;
            case "I,D":
              ID++;
              break;
            case "O,T":
              OT++;
              break;
            case "O,M":
              OM++;
              break;
            case "O,D":
              OD++;
              break;
            case "V,T":
              VT++;
              break;
            case "V,M":
              VM++;
              break;
            case "V,D":
              VD++;
              break;
            case "E,T":
              ET++;
              break;
            case "E,M":
              EM++;
              break;
            case "E,D":
              ED++;
              break;
            case "A,T":
              AT++;
              break;
            case "A,M":
              AM++;
              break;
            case "A,D":
              AD++;
              break;
            case "M,T":
              MT++;
              break;
            case "M,M":
              MM++;
              break;
            case "M,D":
              MD++;
              break;
            case "G,T":
              GT++;
              break;
            case "G,M":
              GM++;
              break;
            case "G,D":
              GD++;
              break;

            default:
              break;
          }
        }
      }

      return [
        {
          L: enneatypeL,
          P: enneatypeP,
          I: enneatypeI,
          O: enneatypeO,
          V: enneatypeV,
          E: enneatypeE,
          A: enneatypeA,
          M: enneatypeM,
          G: enneatypeG,
        },
        {
          breakdown: {
            L: [LT, LM, LD],
            P: [PT, PM, PD],
            I: [IT, IM, ID],
            O: [OT, OM, OD],
            V: [VT, VM, VD],
            E: [ET, EM, ED],
            A: [AT, AM, AD],
            M: [MT, MM, MD],
            G: [GT, GM, GD],
          },
        },
      ];
    }, []);
  }

  return enneatypes;
};

export const calculateCoherence = (answersA: Array<any>, answersB: Array<any>) => {
  let enneatypeL = 0;
  let enneatypeP = 0;
  let enneatypeI = 0;
  let enneatypeO = 0;
  let enneatypeV = 0;
  let enneatypeE = 0;
  let enneatypeA = 0;
  let enneatypeM = 0;
  let enneatypeG = 0;

  if (answersA.length && answersB.length) {
    answersA.forEach((option: any) => {
      answersB.forEach((item) => {
        item.options.forEach((match: any) => {
          if (option.enneatype === "L" && match.value === option.value && match.name === option.name) {
            enneatypeL++;
          }
          if (option.enneatype === "P" && match.value === option.value && match.name === option.name) {
            enneatypeP++;
          }
          if (option.enneatype === "I" && match.value === option.value && match.name === option.name) {
            enneatypeI++;
          }
          if (option.enneatype === "O" && match.value === option.value && match.name === option.name) {
            enneatypeO++;
          }
          if (option.enneatype === "V" && match.value === option.value && match.name === option.name) {
            enneatypeV++;
          }
          if (option.enneatype === "E" && match.value === option.value && match.name === option.name) {
            enneatypeE++;
          }
          if (option.enneatype === "A" && match.value === option.value && match.name === option.name) {
            enneatypeA++;
          }
          if (option.enneatype === "M" && match.value === option.value && match.name === option.name) {
            enneatypeM++;
          }
          if (option.enneatype === "G" && match.value === option.value && match.name === option.name) {
            enneatypeG++;
          }
        });
      });
    });
  }

  return {
    L: enneatypeL,
    P: enneatypeP,
    I: enneatypeI,
    O: enneatypeO,
    V: enneatypeV,
    E: enneatypeE,
    A: enneatypeA,
    M: enneatypeM,
    G: enneatypeG,
  };
};
