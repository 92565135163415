
import { defineComponent, onMounted } from "vue";
import Topbar from "@/layout/Topbar.vue";
import Footer from "@/layout/Footer.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import AuthService from "@/services/auth";
import gsap from "gsap";

export default defineComponent({
  name: "app",
  components: {
    Topbar,
    Footer,
  },
  setup() {
    onMounted(async () => {
      AOS.init();
      AuthService.validateJWT();
    });

    const beforeEnter = (el: any) => {
      el.style.opacity = "0";
      el.style.transform = "translateY(50px)";
    };

    const enter = (el: any, done: () => void) => {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.7,
        onComplete: done,
        // delay: 1 + el.dataset.index * 0.2,
      });
    };

    return { beforeEnter, enter };
  },
});
