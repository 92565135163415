import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Home from "@/views/Home.vue";
import Test from "@/views/Test.vue";
import Login from "@/views/Login.vue";

import SignUp from "@/components/Test/SignUp.vue";
import PartOne from "@/components/Test/PartOne.vue";
import PartTwo from "@/components/Test/PartTwo.vue";
import Result from "@/components/Test/Result.vue";

import Dashboard from "@/views/Dashboard.vue";
import ProfessionalTestResult from "@/views/ProfessionalTestResult.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    children: [
      {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
      },
      {
        path: "/part-one",
        name: "PartOne",
        component: PartOne,
      },
      {
        path: "/part-two",
        name: "PartTwo",
        component: PartTwo,
      },
      {
        path: "/result",
        name: "Result",
        component: Result,
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { auth: true },
  },
  {
    path: "/professional/:id",
    name: "ProfessionalTestResult",
    component: ProfessionalTestResult,
    meta: { auth: true },
  },
  { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
router.beforeEach((to, from, next) => {
  const userLogged = localStorage.getItem("jwt") || null;

  if (to.matched.some((record) => record.meta.auth) || from.matched.some((record) => record.meta.auth)) {
    if (!userLogged) {
      // console.log("USER NOT LOGGED");
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
