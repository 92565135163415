export const lifeProfiles = {
  L: {
    shortDesc:
      "El tipo que suele ser valiente y arrojado. Tiende a ser seguro de sí mismo, atrevido y reactivo. Frecuentemente va por lo que quiere. Le gusta estar al mando. Usualmente adopta comportamiento autoritario y poderoso",
    otherNames: "El Líder, El Guerrero, El Autosuficiente, El Indómito, El Protector, El Proveedor, El Empresario, El Inconformista",
    tags: "fuerte, sólido, franco, protector, justiciero, ingenioso, tenaz, independiente, directo, fiel, autoconfiado, enérgico, terrenal, dominante",
    keyMotivations:
      "Desea ser confiado en sí mismo, guiarse por su propio interés, tener un impacto en el ambiente, triunfar sobre los demás",
    description:
      "Suelen ser personas fuertes enérgicas, vengativas, valientes, protectoras, agresivas, controladoras, seguras, rencorosas y realistas, asertivas y poderosas, instintivas e impulsivas. Es común que les moleste la debilidad propia, que no asumen, y la de los otros, la cual no comprenden. No se permiten confiar en nadie ni en nada",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "Tipo poderoso y dominante",
        "Seguros de sí mismos, fuertes y capaces de imponerse",
        "Protectores y territoriales",
        "Ingeniosos y decididos",
        "Piensan que deben estar al mando",
        "Problemas para intimar con los demás",
        "Pueden volverse retadores e intimidadores",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: LA LUJURIA": [
        "No alude solamente al deseo sexual",
        "Necesidad de vehemencia, dominio y expansión",
        "Busca intensidad, situaciones extremas, al límite",
        "Fuerte, duro, resistente a la emoción",
        "Consciente de su fuerza, menosprecia la debilidad",
      ],
    },
    badMoments: [
      "Controladores",
      "Rebeldes",
      "Insensibles",
      "Dominantes",
      "Egocéntricos",
      "Escépticos",
      "Agresivos",
      "Cínicos",
      "Intimidadores",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Pensar que hay que esforzarse y luchar para hacer que ocurran las cosas",
      "FORMA DE MANIPULACIÓN": "Dominando a los demás y exigiendo que hagan su voluntad",
      "HACE A LOS DEMÁS": "Temiendo que otros le hagan daño o los manipulen, infunden ese miedo a los demás con amenazas beligerantes",
      "MIEDO: ALERTA ROJA": "Que los demás se vuelvan contra ellos y tomen represalias",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien confiar en alguien ni ser vulnerable",
      "MENSAJE PERDIDO": "No serás traicionado",
      "MIEDO BÁSICO": "Ser dañado o controlado por otros",
      "DESEO BÁSICO": "Protegerse",
      "DEGENERA EN": "Lucha constante",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "NEGACIÓN: Redireccionamiento poderoso de la atención y los sentimientos basado en la premeditación y el control",
      UTILIZACIÓN: "Usan la Negación para evitar la debilidad y mantener una autoimagen de seres fuertes y poderosos",
      "MECANISMOS SECUNDARIOS": "Contrarepresión, Contraidentificación, Contraintroyección",
    },
  },
  P: {
    shortDesc:
      "El tipo que suele ser adaptable, buscador de armonía y agradable. Generalmente afable pero firme, constante, tolerante y humilde. Tiende a ser acomodadizo, acogedor y formal. También puede ser renuente, a veces terco e indeciso. Frecuentemente evita los conflictos y se olvida de sí mismo",
    otherNames: "El Pacificador, El Conformista, El Pacífico, El Conciliador, El Sanador, El Optimista, El Reconciliador, El Utópico",
    tags: "apacible, relajado, equitativo, estable, tolerante, natural, amistoso, paciente, tranquilo, receptivo, diplomático, empático, generoso, amable, complaciente, pacífico, receptivo",
    keyMotivations:
      "Desea unión con el otro, mantener las cosas como están, evitar los conflictos y la tensión, ignorar cualquier cosa que lo perturbe, preservar la paz a cualquier precio",
    description:
      "Suelen ser personas pacientes, pacíficas, solidarias, modestas, testarudas. También cómodas y compulsivas. Pueden hacer que las cosas se vuelvan excesivamente lentas, no les gusta tomar decisiones. Con frecuencia, excelentes mediadores. Tienden a detectar con mayor facilidad los gustos y necesidades de los otros, olvidándose de sí mismos. Podrían llegar a tener estallidos de ira y mal humor de manera aislada. Tratan de evitar los conflictos con la tenacidad que los caracteriza, no les gustan los cambios. Suelen ser grandes líderes. A veces, tienen dificultades para saber lo que quieren. Sienten cualquier límite o crítica como un rechazo a la totalidad de su persona. Suelen tener tendencia a la narcotización, como una cierta somnolencia de no estar totalmente despiertos",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "Tipo acomodaticio, humilde",
        "Generalmente conformistas, confiados y estables",
        "Suelen ser afables y bondadosos",
        "Se acomodan con facilidad y ofrecen su apoyo",
        "Usualmente dispuestos a ceder para mantener la paz",
        "Tratan de evitar el conflicto y minimizar cualquier cosa inquietante",
        "Pueden tener problemas de pasividad y tozudez",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: LA PEREZA": [
        "No se trata sólo de holgazanería (Pueden ser muy activos y hábiles)",
        "Deseo de no dejarse afectar por la vida",
        "Se olvida de sí, pone más atención en el resto",
        "Mucha distracción: TV, tecnología (narcotización)",
        "Puede sentir que le pasan por encima",
        "Carácter desapasionado, no tiene rollo de conciencia ni busca demasiado el placer",
        "Autodescuido: deja de ir al doctor, etc…",
      ],
    },
    badMoments: [
      "Inadvertidos",
      "Despistados",
      "Tercos",
      "Obsesivos",
      "Apáticos",
      "Pasivos",
      "Criticones",
      "Inseguros",
      "Resignados",
      "Robóticos",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Adaptarse aparentemente a los demás",
      "FORMA DE MANIPULACIÓN": "Evadiéndose y resistiéndose a los demás de un modo pasivo-agresivo",
      "HACE A LOS DEMÁS":
        "Temiendo sufrir la pérdida de contacto con otros, se desconectan de los demás para hacer que sientan que han perdido la conexión con ellos",
      "MIEDO: ALERTA ROJA": "Que la realidad los obligue a enfrentar sus problemas ",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien hacerse valer",
      "MENSAJE PERDIDO": "Tu presencia importa",
      "MIEDO BÁSICO": "Perder la conexión, a la fragmentación",
      "DESEO BÁSICO": "Estar en paz",
      "DEGENERA EN": "Terca negligencia",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "NARCOTIZACIÓN: Se hace usando la comida, la bebida o la recreación: paseos, TV, etc… o modelos repetitivos de pensar y hacer, que los mantengan dormidos",
      UTILIZACIÓN: "Usan la Narcotización para evitar el conflicto y mantener su autoimagen de seres de paz, cómodos y armoniosos",
      "MECANISMOS SECUNDARIOS": "Deflexión, Confluencia",
    },
  },
  I: {
    shortDesc:
      "El tipo concienzudo, responsable y orientado a mejorar. Suele ser constante, controlado, preciso y de elevados valores. Frecuentemente claro, detallista y afectuoso. También puede ser: Crítico, muchas veces inflexible, testarudo, resentido y crítico consigo mismo",
    otherNames: "El Soberano, El Perfeccionista, El Maestro, El Cruzado, El Moralista, El Idealista",
    tags: "Sensato, moderado, objetivo, ético, moral, bueno, confiable, productivo, idealista, honesto, ordenado, disciplinado, visionario, fiable, trabajador, inteligente, meticuloso, sincero",
    keyMotivations:
      "Desea tener razón, esforzarse al máximo y perfeccionar a los demás, justificar su propia opinión, estar más allá de la crítica para no ser condenado por nadie",
    description:
      "Acostumbran a experimentarse como buenas personas; hacen lo correcto, lo justo; buscan la perfección y tratan de evitar el error, ya que suelen ser aplicadas y meticulosas. Pueden tener una fijación en corregir, organizar y controlar tanto a su entorno como a sí mismos. Tendencia a la ira. Esta ira está asociada a la carencia que sienten por no sentirse completos y a la decepción que experimentan ante la búsqueda incesante de la perfección. Habitualmente son trabajadores disciplinados y enjuiciadores inflexibles. No les suelen resultar fáciles los placeres. Tienden a reprimir sus instintos",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "Tipo idealista y de sólidos principios",
        "Mayormente ético y concienzudo, fuerte sentido del bien y el mal",
        "Busca esforzarse siempre por mejorar las cosas",
        "Teme cometer errores",
        "Usualmente bien organizado, ordenado y meticuloso",
        "Trata de mantener valores elevados",
        "Puede resultar crítico y perfeccionista",
        "Puede presentar problemas de rabia e impaciencia reprimidas",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: LA IRA": [
        "Es más bien resentimiento, porque la ira está reprimida",
        "Frustración continua consigo mismo y con el mundo",
        "Niño que tuvo que hacerse adulto rápidamente",
        "Generalmente exacto, preciso, obsesivo",
        "Postura rígida, aires de superioridad",
        "Tiende a sermonear",
        "Suele cumplir la norma y exigir su cumplimiento",
      ],
    },
    badMoments: [
      "Críticos",
      "Rígidos",
      "Dogmáticos",
      "Arrogantes",
      "Celosos",
      "Obsesivos",
      "Severos con ellos y con los otros",
      "Llenos de angustias",
      "Intransigentes",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Creer que se tiene la obligación personal de arreglarlo todo",
      "FORMA DE MANIPULACIÓN": "Corrigiendo a los demás, insistiendo en que compartan sus criterios o valores",
      "HACE A LOS DEMÁS":
        "Temiendo ser malos, corruptos o defectuosos en algún aspecto, destacan la maldad, la corrupción o los defectos de los demás",
      "MIEDO: ALERTA ROJA": "Que sus ideales estén equivocados y sean contraproducentes",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien cometer errores",
      "MENSAJE PERDIDO": "Eres bueno",
      "MIEDO BÁSICO": "Ser malo, corrupto, perverso e imperfecto",
      "DESEO BÁSICO": "Integridad ",
      "DEGENERA EN": "Perfeccionismo crítico",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "FORMACIÓN REACTIVA: Consiste en sentir una cosa y hacer exactamente lo contrario como cuando, no obstante resentidos, actúan agradablemente",
      UTILIZACIÓN: "Usan la Transfiguración de la Reacción para evitar la furia abierta y mantener su autoimagen de perfectos",
      "MECANISMOS SECUNDARIOS": "Represión",
    },
  },
  O: {
    shortDesc:
      "Tipo afectuoso, deseoso de ayudar y orientado a las relaciones. Casi siempre atento a los sentimientos de los demás, optimista, generoso, simpático y buen consejero. También puede ser: Orgulloso, entrometido, histriónico, incapaz de decir no, excesivamente complaciente",
    otherNames:
      "El Benefactor, El Servicial, El Dador, El Altruista, El Amante, El Celador, El Complaciente, El Permisor, El amigo especial, El Seductor, El Coordinador",
    tags: "Afectuoso, humanitario, generoso, considerado, atento, amable, compasivo, cuidador, servicial, adaptable, iluminador, entusiasta, empático, cariñoso, posesivo, delicado, dúctil, intuitivo, solidario",
    keyMotivations:
      "Desea ser querido, expresar sus sentimientos por los demás, ser necesitado y apreciado, obligar a los demás a responderle, vindicar todo lo que clama acerca de sí mismo",
    description:
      "Suelen ser serviciales, sensibles, amables y útiles; saben detectar las necesidades de los demás. Tienden a ayudar, aunque no se lo pidan. Pueden ser altruistas, posesivos, aduladores, sobreprotectores, manipuladores. Son orgullosos, necesitan ser considerados necesarios y les cuesta identificar sus necesidades. Esta falta de contacto con sus necesidades suele provocarles problemas con la salud. Tienden a confundir el “dar” con “donarse”, teniendo expectativas hacia los otros difíciles de satisfacer. Muchas veces tienen dificultades para bajar la intensidad emocional",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "Tipo preocupado, orientado a los demás",
        "Usualmente comprensivo, sincero y bondadoso",
        "Generalmente amistoso, generoso y abnegado",
        "Puede ser sentimental, adulador y obsequioso",
        "Desea intimar con los demás y hacerse necesario",
        "Problemas para cuidar de sí mismo y reconocer sus propias necesidades",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: EL ORGULLO": [
        "Soberbia: Incapacidad de reconocer sus propios sufrimientos",
        "Niega sus necesidades ayudando a otros",
        "Vanagloria: orgullo por la propia virtud",
        "Se autoengrandece: Exijo ser amado. Falsa generosidad.",
        "Maneja las relaciones desde la abundancia: experto en seducir, en enamorar.",
        "Muchas veces histriónico, autoindulgente y permisivo",
        "Puede también ser invasor de espacios, parecer natural, pero no serlo.",
        "Persona atractiva, ondulante, suele conseguir lo que se propone",
      ],
    },
    badMoments: [
      "Se hacen las víctimas",
      "Indiscretos",
      "Manipuladores",
      "Posesivos",
      "Histéricos",
      "Comodones",
      "Exageradamente Expresivos",
      "Evasivos",
      "Dominantes",
      "Autoritarios",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Creer que hay que tender la mano a los demás para conquistarloss",
      "FORMA DE MANIPULACIÓN": "Descubriendo las necesidades y deseos de los demás, creando así dependencias",
      "HACE A LOS DEMÁS": "Temiendo no ser deseados ni amados, hacen sentirse indignos de amor, generosidad o atención a los demás",
      "MIEDO: ALERTA ROJA": "Estar ahuyentando a sus amigos y seres queridos",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien tener necesidades",
      "MENSAJE PERDIDO": "Eres deseado",
      "MIEDO BÁSICO": "Ser indigno de amor",
      "DESEO BÁSICO": "Ser amado",
      "DEGENERA EN": "Necesidad de ser necesitado",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "REPRESIÓN: No se permite reconocer sentimientos “inaceptables”, sino que los convierte en la más atractiva fuente de energía emocional",
      UTILIZACIÓN:
        "Usan la Represión de las demandas y sentimientos propios para evitar sentir que necesitan y mantener su autoimagen de ayudadores disponibles",
      "MECANISMOS SECUNDARIOS": "Amplificación Emocional",
    },
  },
  V: {
    shortDesc:
      "El tipo industrioso, rápido, orientado al objetivo, a los resultados y al éxito. Generalmente eficiente, seguro de sí mismo, entusiasta y dinámico. Suele demostrar el afecto haciendo; tiende a ser ambicioso, emprendedor y optimista. También puede ser impaciente, indiferente a los sentimientos o desatento en las relaciones, competitivo, precipitado, dado a promocionarse, impulsivo y desbordado",
    otherNames:
      "El Eficiente, El Buscador de Status, El Carismático, El Eficaz, El Logrador, El Motivador, El Modelo, El Dechado, El Comunicador, El Competente, El Atractivo",
    tags: "Admirable, deseable, atractivo, sobresaliente, eficaz, optimista, confiado, automotivado, enérgico, práctico, seguro, competitivo, diligente, eficiente, creativo, imaginativo",
    keyMotivations: "Desea ser afirmado, distinguirse de los demás, recibir atención, ser admirado e impresionar a los demás.",
    description:
      "Suelen ser personas exitosas, eficientes, prácticas, vanidosas, seguras, autosuficientes, frías, productivas, camaleónicas, persistentes, egoístas, ambiciosas. Suelen estar centradas en los resultados, evitan el fracaso a cualquier costo, reemplazan los sentimientos por las acciones. Se identifican con la tarea que realizan. Disponen de mucha energía sin intensidad emocional. Les entregan el poder a los otros, necesitando permanentemente la aprobación externa del éxito. Puede minimizar al sujeto y a agrandar su imagen con este fin",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "Tipo adaptable y orientado al éxito",
        "Tiende a ser seguro de sí mismo, atractivo y encantador",
        "Generalmente ambicioso, competente y enérgico",
        "Consciente de su posición, motivado por el progreso personal",
        "Le preocupa su imagen y lo que piensen los demás de él",
        "Podría tener problemas de adicción al trabajo y de competitividad",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: LA VANIDAD": [
        "Falsedad: Creencia de que sólo somos nuestro ego",
        "Cultiva más su envase que su interior",
        "Usualmente exitoso, adecuado, siempre bien puesto",
        "Se adapta rápidamente, juega bien en cualquier rol",
        "Tiende a autoengañarse y puede que al resto también",
        "Orientación mercantil, descubre lo que otros necesitan",
        "Carácter superficial: le cuestan las relaciones profundas",
        "Estilo narcisista: Yo sé, yo tengo, he logrado",
        "Búsqueda de reconocimiento",
      ],
    },
    badMoments: [
      "Narcisistas",
      "Pretenciosos",
      "Vanidosos",
      "Superficiales",
      "Extremadamente",
      "Competitivos",
      "Dependientes",
      "Arrogantes",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Comenzar a actuar para obtener posición y atención",
      "FORMA DE MANIPULACIÓN": "Mostrándose encantador ante los demás y adoptando cualquier imagen que funcione",
      "HACE A LOS DEMÁS":
        "Temiendo ser inútiles y no valer nada, hacen que otros se sientan inútiles tratándolos con arrogancia o desprecio",
      "MIEDO: ALERTA ROJA": "Estar fracasando; que sus pretensiones sean vanas y fraudulentas",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien tener sentimientos de identidad",
      "MENSAJE PERDIDO": "No está bien tener sentimientos de identidad",
      "MIEDO BÁSICO": "Ser despreciable o carecer de valor inherente",
      "DESEO BÁSICO": "Ser valioso",
      "DEGENERA EN": "Afán de éxito",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO": "IDENTIFICACIÓN: Tipo de rol intenso donde uno mismo se recrea y a la vez se esconde, de la imagen",
      UTILIZACIÓN: "Usan la Identificación (Hacer de camaleón) para evitar el fracaso y mantener su autoimagen de exitosos",
      "MECANISMOS SECUNDARIOS": "Negación",
    },
  },
  E: {
    shortDesc:
      "El tipo idealista, muy sensible, comprensivo, afectuoso, vehemente y orientado a lo especial o extraordinario. De disposición creativa, auténtico consigo mismo, introspectivo y expresivo. También puede ser voluble, tímido, insatisfecho y a veces ensimismado",
    otherNames:
      "El Artista, El Artífice, El Especial, El Creativo, El Personalista, El Individualista, El Romántico, El Melancólico, El Esteta",
    tags: "Sensible, diferente, único, clemente, intuitivo, profundo, sincero, cálido, compasivo, introspectivo, creativo, original, refinado",
    keyMotivations:
      "Desea comprenderse, expresarse en algo hermoso, retraerse para proteger sus sentimientos, cuidar necesidades emocionales antes de atender cualquier otra cosa",
    description:
      "Suelen identificarse totalmente con lo que sienten, y pueden llegar a sentirse raros y que no encajan. Son en general empáticos, originales, fantasiosos, románticos, creativos, soñadores, idealistas, melancólicos. Están muy conectados emocionalmente, sienten envidia asociada a que los demás tienen algo que a ellos les falta. Suelen creer que les falta talento, lo que generalmente es falta de perseverancia. Desean ser el centro emocional de los otros",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "Tipo romántico e introspectivo",
        "Frecuentemente consciente de sí mismo, sensible, reservado y callado",
        "Tiende a ser demostrativo, sincero y personal emocionalmente",
        "Puede ser caprichoso y tímido",
        "Suele ocultarse de los demás porque se siente vulnerable o defectuoso",
        "Puede sentirse ajeno a las formas normales de vivir",
        "Problemas de autocomplacencia y autocompasión",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: LA ENVIDIA": [
        "Sensación de que le falta algo esencial",
        "Creencia de que los otros poseen cualidades de las que él carece",
        "Tiende a anhelar lo que le falta y no ver lo que tiene",
        "Postura de víctima, suele compararse negativamente",
        "Tiende a relacionarse a través del sufrimiento",
        "Usualmente demandante, no se valora, se siente inferior",
        "Generalmente se culpa por sentirse menos: autosabotaje",
        "Suele sentirse especial, artista incomprendido",
        "Estilo pesimista y crítico",
      ],
    },
    badMoments: [
      "Depresivos",
      "Dramáticos",
      "Vergonzosos",
      "Temperamentales",
      "Se sienten culpables por todo",
      "Moralistas",
      "Tímidos",
      "Tercos",
      "Lunáticos",
      "Muy Introvertidos",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Aferrarse a los sentimientos e intensificarlos mediante la imaginación",
      "FORMA DE MANIPULACIÓN": "Siendo temperamental y obligando a los demás a andar con pies de plomo",
      "HACE A LOS DEMÁS":
        "Temiendo no tener identidad ni importancia personal, tratan a los demás con desdén, como si no fueran nadie y no valieran ni importaran nada",
      "MIEDO: ALERTA ROJA": "Estar estropeando sus vidas y desperdiciando sus oportunidades",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien ser demasiado práctico ni demasiado feliz",
      "MENSAJE PERDIDO": "Se te ve por lo que eres",
      "MIEDO BÁSICO": "Carecer de identidad o no ser importante",
      "DESEO BÁSICO": "Ser uno mismo",
      "DEGENERA EN": "Autocomplacencia",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "INTROYECCIÓN: Es tanto un esfuerzo por superar las deficiencias trayendo valores de fuera de sí mismo, como el hábito de internalizar reproches por lo que sale mal.",
      UTILIZACIÓN: "Usan la Introyección para evitar la ordinariez y mantener su autoimagen de seres auténticos, especiales y diferentes.",
      "MECANISMOS SECUNDARIOS": "Represión, Intensificación, Retroflexión",
    },
  },
  A: {
    shortDesc:
      "El tipo autosuficiente, poco exigente, discretamente afectuoso y bien informado. Generalmente es curioso, objetivo, sistemático, analítico, considerado y discreto. También puede ser: poco comunicativo, dado al secretismo, indiferente, falto de firmeza y distanciado. Usualmente parco en la expresión de sentimientos y excesivamente reservado",
    otherNames:
      "El Pensador, El Filósofo, El Intelectual, El Observador, El Innovador, El Especialista, El Radical, El Investigador, EL Excéntrico",
    tags: "Perceptivo, curioso, independiente, perspicaz, objetivo, analítico, lógico, perseverante, sensitivo, sabio, excéntrico, receptivo, metódico, ordenado",
    keyMotivations:
      "Quiere entender el ambiente, adquirir más conocimientos, interpretar todo como un modo de defender al sí mismo de las amenazas ambientales",
    description:
      "Suelen ser analíticos, lógicos, sencillos, inexpresivos, avaros, meticulosos, arrogantes, detallistas, curiosos, desapegados, callados, reservados, escépticos e inventivos. Es común que estén centrados en las ideas y pensamientos, usualmente les cuesta pasar a la acción. Tienen tendencia a pensar la vida más que a vivirla. Podrían estar muy desconectados de las sensaciones físicas",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "El tipo vehemente y cerebral",
        "Generalmente despabilado, perspicaz y curioso",
        "Suele ser capaz de concentrarse y enfocar la atención en desarrollar ideas y habilidades complejas",
        "Usualmente independiente e innovador",
        "Puede obsesionarse con sus pensamientos y elaboraciones imaginarias",
        "Tiende a desligarse de las cosas, pero es muy nervioso y vehemente",
        "Problemas de aislamiento, excentricidad y nihilismo",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: LA AVARICIA": [
        "Como cree que carece de recursos internos, las relaciones intensas tienden a agotarle",
        "Suelen abstenerse del contacto con el mundo para reducir al mínimo sus necesidades",
        "Puede mostrarse tacaño de sí mismo: de sus emociones, de su tiempo, de involucrarse",
        "Generalmente se aísla. Muy en el pensamiento",
        "Tiende a suplir su falta de relaciones con lo intelectual",
        "No sólo se aíslan de otras personas, aíslan unas experiencias de otras: “Compartimentalizan",
      ],
    },
    badMoments: [
      "Extremadamente reservados",
      "Intelectualmente arrogantes",
      "Tercos",
      "Distantes",
      "Criticones",
      "Inseguros",
      "Negativos",
      "Autistas emocionales",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Apartarse de la realidad para abrazar conceptos y mundos mentales",
      "FORMA DE MANIPULACIÓN": "Manteniéndose preocupado y separándose emocionalmente de los demás",
      "HACE A LOS DEMÁS":
        "Temiendo ser impotentes, incapaces e incompetentes, procuran que otros se sientan impotentes, incapaces, incompetentes y estúpidos",
      "MIEDO: ALERTA ROJA": "No encontrar jamás un lugar en el mundo o entre las personas",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien sentirse a gusto en el mundo",
      "MENSAJE PERDIDO": "Tus necesidades no son problema",
      "MIEDO BÁSICO": "Ser inútil, incapaz o incompetente",
      "DESEO BÁSICO": "Ser competente",
      "DEGENERA EN": "Especialización inútil",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "AISLAMIENTO: Puede ser físico o geográfico, pero también significa amputar las emociones propias en una operación completamente mental",
      UTILIZACIÓN: "Usa el Aislamiento para evitar el vacío y mantener su autoimagen de experto",
      "MECANISMOS SECUNDARIOS": "Escisión del yo, Formación Reactiva",
    },
  },
  M: {
    shortDesc:
      "El tipo que tiende a ser fiable, responsable, curioso, sumiso y buen amigo. Generalmente afectuoso, colaborador, analítico y perseverante. También puede ser dudoso y escéptico; indeciso, inquieto y vigilante; a veces desafiante y/o temeroso, o bien muy cauteloso o muy arriesgado e indagador hasta el exceso",
    otherNames:
      "El Lealista, El Organizado, El Leal, El Incondicional, El Guardián, El Fiel Creyente, El Escéptico, El Apagafuegos, El Tradicionalista, El Comprometido",
    tags: "Leal, compasivo, práctico, responsable, fidedigno, cuidadoso, fiable, agradable, formal, previsor, cálido, íntegro, amable, cumplidor, dependiente",
    keyMotivations:
      "Desea seguridad, ser querido y tener la aprobación de los demás, poner a prueba las actitudes de los demás hacia él, luchar contra la angustia y la inseguridad",
    description:
      "Suelen ser personas responsables, sufridoras, ansiosas, escépticas, pesimistas, comprometidas, leales, ambivalentes. Frecuentemente proyectan en los otros lo que sienten, acusándolos. Suelen estar atravesados por una permanente duda que puede tomar diferentes formas. Están siempre en alerta y tienen grandes dificultades para relajar las defensas. Suelen esconderse bien de la mirada de los otros, sin poder escaparse de sus profundas angustias e incertidumbres",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "El tipo comprometido, orientado a la seguridad",
        "Suelen ser dignos de confianza, trabajadores y responsables",
        "Muchas veces muestran una actitud defensiva, evasiva y muy nerviosa",
        "Pueden ser cautelosos e indecisos, aunque también reactivos, desafiantes y rebeldes",
        "Problemas de inseguridad y desconfianza",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: EL MIEDO": [
        "Más que miedo es ansiedad, porque temen cosas que no están ocurriendo",
        "Tienden a vivir en un estado constante de aprensión, preocupados por acontecimientos futuros.",
        "Suspicaces, precavidos: mucha tarjeta, mucho seguro, dónde está la salida de emergencia.",
        "Sensación general de angustia y/o ansiedad",
        "Lo más doloroso: Se autoacusa por lo que perdió",
        "Mucha energía puesta en la duda. Demasiado rollo mental",
      ],
    },
    badMoments: [
      "Ansiosos",
      "Imprevisibles",
      "Criticones",
      "Paranoicos",
      "Actúan a la defensiva",
      "Rígidos",
      "Autodestructivos",
      "Ponen a prueba a los demás",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Comenzar a depender de algo exterior para orientarse",
      "FORMA DE MANIPULACIÓN": "Quejándose y poniendo a prueba la lealtad o el compromiso de los demás",
      "HACE A LOS DEMÁS":
        "Temiendo carecer de apoyo y orientación, socaban los sistemas de apoyo de otros, tratando de aislarlos de alguna forma",
      "MIEDO: ALERTA ROJA": "Que sus actos perjudiquen su seguridad",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien confiar en sí mismo",
      "MENSAJE PERDIDO": "Estás seguro",
      "MIEDO BÁSICO": "Carecer de apoyo u orientación",
      "DESEO BÁSICO": "Seguridad ",
      "DEGENERA EN": "Fuerte apego a creencias",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "PROYECCIÓN: Manera de atribuir a otros lo que uno no puede aceptar de sí mismo, tanto lo positivo, como lo negativo",
      UTILIZACIÓN:
        "Usan la Proyección para evitar la transgresión (y el autorechazo) y mantener su autoimagen de fieles, leales y obedientes",
      "MECANISMOS SECUNDARIOS": "Identificación",
    },
  },
  G: {
    shortDesc:
      "Suele ser del tipo optimista, encantador, afectuoso y exuberante. Generalmente espontáneo, versátil, alegre, buscador de posibilidades y placer. Sintetizador de ideas, orientado a la oportunidad; pensador rápido y aventurero. También podría ser: desconsiderado, desenfocado, no comprometido, impetuoso y egoísta. Suele evitar el dolor y rechazar la autorida",
    otherNames:
      "El Generalista, El Impulsivo, El Alegre, El Optimista, El Entusiasta, El Multitareas, El Niño Prodigio, El Diletante, El Entendido, El Estimulador, El Planificador, El Aventurero",
    tags: "Entusiasta, libre, espontáneo, alegre, vehemente, enérgico, positivo, imaginativo, optimista, entusiasta, confiado, encantador, curioso, divertido, realizador, productivo, generoso, rápido, seguro, seductor",
    keyMotivations: "Desea ser feliz, pasarlo bien y divertirse, hacer y tener más de todo, escapar de la angustia",
    description:
      "Suelen ser personas caracterizadas por su optimismo y espontaneidad. Usualmente ansiosos, creativos, ágiles de mente, entusiastas, amigables, egoístas, rebeldes, narcisistas, evitan el conflicto, reenmarcan las situaciones negativas colocándoles en un marco positivo. Tendencia a ser muy divertidos, les cuesta el compromiso, conectarse con los sentimientos propios y aún más ver el dolor ajeno. Sienten las elecciones como una pérdida del resto de las oportunidades",
    features: {
      "SUELE PRESENTAR ALGUNOS DE ESTOS RASGOS": [
        "El tipo productivo y ajetreado",
        "Tienden a ser versátiles, optimistas y espontáneos; juguetones, animosos y prácticos",
        "Pueden abarcar demasiado, ser desorganizados e indisciplinados",
        "Buscan experiencias nuevas y estimulantes, pero la actividad continuada los aturde y agota",
        "Problemas de superficialidad e impulsividad",
      ],
      "MANIFESTACIÓN DE SU PASIÓN: LA GULA": [
        "Deseo insaciable de llenarse de experiencias.",
        "Intentan superar su vacío interior realizando numerosas ideas y actividades estimulantes, nunca suficientes",
        "Alma de la fiesta. Busca sustitutos placenteros al dolor",
        "Talentoso en engatusar: cierta sensación de superioridad: más derechos que otros.",
        "Podría llegar a ser inescrupuloso, pocas reglas",
        "Carácter pensador, estratega, va un paso adelante, ya no está en una conversación, está en la próxima",
        "Autoindulgencia, derecho a todo, llevarse las cosas del vecino sin hacerse cargo.",
      ],
    },
    badMoments: [
      "Narcisistas",
      "Desconcertados",
      "Rebeldes",
      "Indisciplinados",
      "Posesivos",
      "Maniáticos",
      "Autodestructivos",
      "Inquietos",
      "Permisivos",
    ],
    alerts: {
      "LLAMADO A DESPERTAR": "Pensar que hay algo mejor en otra parte",
      "FORMA DE MANIPULACIÓN": "Confundiendo a los demás e insistiendo en que cumplan sus exigencias",
      "HACE A LOS DEMÁS":
        "Temiendo estar atrapados en el sufrimiento o privación, causan sufrimientos y hacen sentirse desvalidos a los demás de diversas formas",
      "MIEDO: ALERTA ROJA": "Que sus actividades les atraigan sufrimiento e infelicidad",
    },
    origins: {
      "MENSAJE RECIBIDO": "No está bien depender de alguien para nada",
      "MENSAJE PERDIDO": "Estarás cuidado y atendido",
      "MIEDO BÁSICO": "Ser desvalido o quedar atrapado en el dolor",
      "DESEO BÁSICO": "Ser feliz",
      "DEGENERA EN": "Escapismo frenético",
    },
    defenseMechanisms: {
      "MECANISMO PRIMARIO":
        "RACIONALIZACIÓN: Estilo de explicar y justificar todo para mantener lejos el dolor y negarse a admitir la responsabilidad",
      UTILIZACIÓN: "Usan la Racionalización para evitar el dolor y mantener su autoimagen de ser positivo, optimista y divertido",
      "MECANISMOS SECUNDARIOS": "Idealización, Sublimación",
    },
  },
};
