export const partOneData = [
  { id: "q1", name: "Cautivador", value: false, category: "M", enneatype: "O" },
  { id: "q2", name: "Altruista", value: false, category: "T", enneatype: "O" },
  { id: "q3", name: "Absorbente", value: false, category: "D", enneatype: "O" },
  { id: "q4", name: "Excéntrico", value: false, category: "D", enneatype: "A" },
  { id: "q5", name: "Autónomo", value: false, category: "T", enneatype: "A" },
  { id: "q6", name: "Introvertido", value: false, category: "M", enneatype: "A" },
  { id: "q7", name: "Disperso", value: false, category: "M", enneatype: "G" },
  { id: "q9", name: "Hablador", value: false, category: "D", enneatype: "G" },
  { id: "q9", name: "Gozador", value: false, category: "T", enneatype: "G" },
  { id: "q10", name: "Prolijo", value: false, category: "T", enneatype: "I" },
  { id: "q11", name: "Detallista", value: false, category: "M", enneatype: "I" },
  { id: "q12", name: "Denunciante", value: false, category: "D", enneatype: "I" },
  { id: "q13", name: "Diligente", value: false, category: "M", enneatype: "V" },
  { id: "q14", name: "Vanidoso", value: false, category: "D", enneatype: "V" },
  { id: "q15", name: "Eficaz", value: false, category: "T", enneatype: "V" },
  { id: "q16", name: "Protector", value: false, category: "T", enneatype: "L" },
  { id: "q17", name: "Desafiante", value: false, category: "M", enneatype: "L" },
  { id: "q18", name: "Audaz", value: false, category: "D", enneatype: "L" },
  { id: "q19", name: "Sofisticado", value: false, category: "M", enneatype: "E" },
  { id: "q20", name: "Empático", value: false, category: "T", enneatype: "E" },
  { id: "q21", name: "Vergonzoso", value: false, category: "D", enneatype: "E" },
  { id: "q22", name: "Tozudo", value: false, category: "D", enneatype: "P" },
  { id: "q23", name: "Diplomático", value: false, category: "T", enneatype: "P" },
  { id: "q24", name: "Complaciente", value: false, category: "M", enneatype: "P" },
  { id: "q25", name: "Comprometido", value: false, category: "M", enneatype: "M" },
  { id: "q26", name: "Ansioso", value: false, category: "D", enneatype: "M" },
  { id: "q27", name: "Leal", value: false, category: "T", enneatype: "M" },
  { id: "q28", name: "Considerado", value: false, category: "T", enneatype: "O" },
  { id: "q29", name: "Atrayente", value: false, category: "M", enneatype: "O" },
  { id: "q30", name: "Aguantador", value: false, category: "D", enneatype: "O" },
  { id: "q30", name: "Original", value: false, category: "M", enneatype: "E" },
  { id: "q31", name: "Complejo", value: false, category: "D", enneatype: "E" },
  { id: "q32", name: "Perceptivo", value: false, category: "T", enneatype: "E" },
  { id: "q33", name: "Mediador", value: false, category: "T", enneatype: "P" },
  { id: "q34", name: "Acomodaticio", value: false, category: "M", enneatype: "P" },
  { id: "q36", name: "Pacífico", value: false, category: "D", enneatype: "P" },
  { id: "q37", name: "Cauteloso", value: false, category: "M", enneatype: "M" },
  { id: "q38", name: "Responsable", value: false, category: "T", enneatype: "M" },
  { id: "q39", name: "Demoroso", value: false, category: "D", enneatype: "M" },
  { id: "q40", name: "Abnegado", value: false, category: "D", enneatype: "O" },
  { id: "q41", name: "Comprensivo", value: false, category: "T", enneatype: "O" },
  { id: "q42", name: "Desprendido", value: false, category: "M", enneatype: "O" },
  { id: "q43", name: "Enfocado", value: false, category: "M", enneatype: "A" },
  { id: "q44", name: "Aislado", value: false, category: "D", enneatype: "A" },
  { id: "q45", name: "Visionario", value: false, category: "T", enneatype: "A" },
  { id: "q46", name: "Tolerante", value: false, category: "T", enneatype: "P" },
  { id: "q47", name: "Modesto", value: false, category: "M", enneatype: "P" },
  { id: "q48", name: "Despistado", value: false, category: "D", enneatype: "P" },
  { id: "q49", name: "Inquieto", value: false, category: "M", enneatype: "G" },
  { id: "q50", name: "Volátil", value: false, category: "D", enneatype: "G" },
  { id: "q51", name: "Desenvuelto", value: false, category: "T", enneatype: "G" },
  { id: "q52", name: "Reformador", value: false, category: "T", enneatype: "I" },
  { id: "q53", name: "Perfeccionista", value: false, category: "M", enneatype: "I" },
  { id: "q54", name: "Idealista", value: false, category: "D", enneatype: "I" },
  { id: "q55", name: "Imponente", value: false, category: "M", enneatype: "L" },
  { id: "q56", name: "Seguro", value: false, category: "T", enneatype: "L" },
  { id: "q57", name: "Impulsivo", value: false, category: "D", enneatype: "L" },
  { id: "q58", name: "Popular", value: false, category: "D", enneatype: "V" },
  { id: "q59", name: "Asertivo", value: false, category: "T", enneatype: "V" },
  { id: "q60", name: "Ambicioso", value: false, category: "M", enneatype: "V" },
  { id: "q61", name: "Discreto", value: false, category: "M", enneatype: "E" },
  { id: "q62", name: "Callado", value: false, category: "D", enneatype: "E" },
  { id: "q63", name: "Contemplativo", value: false, category: "T", enneatype: "E" },
  { id: "q64", name: "Culto", value: false, category: "T", enneatype: "A" },
  { id: "q65", name: "Aplicado", value: false, category: "M", enneatype: "A" },
  { id: "q66", name: "Solitario", value: false, category: "D", enneatype: "A" },
  { id: "q67", name: "Caritativo", value: false, category: "M", enneatype: "O" },
  { id: "q68", name: "Servicial", value: false, category: "D", enneatype: "O" },
  { id: "q69", name: "Emocional", value: false, category: "T", enneatype: "O" },
  { id: "q70", name: "Expresivo", value: false, category: "T", enneatype: "O" },
  { id: "q71", name: "Sacrificado", value: false, category: "M", enneatype: "O" },
  { id: "q72", name: "Encantador", value: false, category: "D", enneatype: "O" },
  { id: "q73", name: "Concienzudo", value: false, category: "M", enneatype: "I" },
  { id: "q74", name: "Equitativo", value: false, category: "T", enneatype: "I" },
  { id: "q75", name: "Crítico", value: false, category: "D", enneatype: "I" },
  { id: "q76", name: "Atolondrado", value: false, category: "D", enneatype: "G" },
  { id: "q77", name: "Ágil", value: false, category: "T", enneatype: "G" },
  { id: "q78", name: "Juguetón", value: false, category: "M", enneatype: "G" },
  { id: "q79", name: "Atractivo", value: false, category: "M", enneatype: "V" },
  { id: "q80", name: "Exitista", value: false, category: "D", enneatype: "V" },
  { id: "q81", name: "Agradable", value: false, category: "T", enneatype: "V" },
  { id: "q82", name: "Cordial", value: false, category: "T", enneatype: "M" },
  { id: "q83", name: "Compañero", value: false, category: "M", enneatype: "M" },
  { id: "q84", name: "Pesimista", value: false, category: "D", enneatype: "M" },
  { id: "q85", name: "Cómodo", value: false, category: "M", enneatype: "P" },
  { id: "q86", name: "Dubitativo", value: false, category: "D", enneatype: "P" },
  { id: "q87", name: "Confiado", value: false, category: "T", enneatype: "P" },
  { id: "q88", name: "Apto", value: false, category: "T", enneatype: "V" },
  { id: "q89", name: "Trabajólico", value: false, category: "M", enneatype: "V" },
  { id: "q90", name: "Ganador", value: false, category: "D", enneatype: "V" },
  { id: "q91", name: "Destacado", value: false, category: "M", enneatype: "V" },
  { id: "q92", name: "Adecuado", value: false, category: "T", enneatype: "V" },
  { id: "q93", name: "Impaciente", value: false, category: "D", enneatype: "V" },
  { id: "q94", name: "Inestable", value: false, category: "D", enneatype: "E" },
  { id: "q95", name: "Delicado", value: false, category: "T", enneatype: "E" },
  { id: "q96", name: "Inspirado", value: false, category: "M", enneatype: "E" },
  { id: "q97", name: "Atento", value: false, category: "M", enneatype: "O" },
  { id: "q98", name: "Sufrido", value: false, category: "D", enneatype: "O" },
  { id: "q99", name: "Sentimental", value: false, category: "T", enneatype: "O" },
  { id: "q100", name: "Decidido", value: false, category: "T", enneatype: "L" },
  { id: "q101", name: "Enérgico", value: false, category: "M", enneatype: "L" },
  { id: "q102", name: "Autoritario", value: false, category: "D", enneatype: "L" },
  { id: "q103", name: "Constante", value: false, category: "M", enneatype: "I" },
  { id: "q104", name: "Enojado", value: false, category: "D", enneatype: "I" },
  { id: "q105", name: "Organizado", value: false, category: "T", enneatype: "I" },
  { id: "q106", name: "Artístico", value: false, category: "T", enneatype: "E" },
  { id: "q107", name: "Extraño", value: false, category: "M", enneatype: "E" },
  { id: "q108", name: "Prudente", value: false, category: "D", enneatype: "E" },
  { id: "q109", name: "Perspicaz", value: false, category: "M", enneatype: "A" },
  { id: "q110", name: "Intelectual", value: false, category: "T", enneatype: "A" },
  { id: "q111", name: "Vehemente", value: false, category: "D", enneatype: "A" },
  { id: "q112", name: "Dependiente", value: false, category: "D", enneatype: "M" },
  { id: "q113", name: "Confiable", value: false, category: "T", enneatype: "M" },
  { id: "q114", name: "Cuidadoso", value: false, category: "M", enneatype: "M" },
  { id: "q115", name: "Serio", value: false, category: "M", enneatype: "I" },
  { id: "q116", name: "Tenso", value: false, category: "D", enneatype: "I" },
  { id: "q117", name: "Recto", value: false, category: "T", enneatype: "I" },
  { id: "q118", name: "Ocurrente", value: false, category: "T", enneatype: "G" },
  { id: "q119", name: "Gracioso", value: false, category: "M", enneatype: "G" },
  { id: "q120", name: "Distraído", value: false, category: "D", enneatype: "G" },
  { id: "q121", name: "Desconfiado", value: false, category: "M", enneatype: "M" },
  { id: "q122", name: "Suspicaz", value: false, category: "D", enneatype: "M" },
  { id: "q123", name: "Amigable", value: false, category: "T", enneatype: "M" },
  { id: "q124", name: "Curioso", value: false, category: "T", enneatype: "A" },
  { id: "q125", name: "Desapegado", value: false, category: "M", enneatype: "A" },
  { id: "q126", name: "Cerebral", value: false, category: "D", enneatype: "A" },
  { id: "q127", name: "Inagotable", value: false, category: "M", enneatype: "G" },
  { id: "q128", name: "Optimista", value: false, category: "T", enneatype: "G" },
  { id: "q129", name: "Bromista", value: false, category: "D", enneatype: "G" },
  { id: "q130", name: "Arriesgado", value: false, category: "D", enneatype: "L" },
  { id: "q131", name: "Fuerte", value: false, category: "T", enneatype: "L" },
  { id: "q132", name: "Irreverente", value: false, category: "M", enneatype: "L" },
  { id: "q133", name: "Mimetizado", value: false, category: "M", enneatype: "P" },
  { id: "q134", name: "Pasivo", value: false, category: "D", enneatype: "P" },
  { id: "q135", name: "Bondadoso", value: false, category: "T", enneatype: "P" },
  { id: "q136", name: "Competente", value: false, category: "T", enneatype: "V" },
  { id: "q137", name: "Calificado", value: false, category: "M", enneatype: "V" },
  { id: "q138", name: "Competitivo", value: false, category: "D", enneatype: "V" },
  { id: "q139", name: "Controlador", value: false, category: "M", enneatype: "L" },
  { id: "q140", name: "Drástico", value: false, category: "D", enneatype: "L" },
  { id: "q141", name: "Resuelto", value: false, category: "T", enneatype: "L" },
  { id: "q142", name: "Grupal", value: false, category: "T", enneatype: "M" },
  { id: "q143", name: "Cuestionador", value: false, category: "M", enneatype: "M" },
  { id: "q144", name: "Precavido", value: false, category: "D", enneatype: "M" },
  { id: "q145", name: "Humilde", value: false, category: "M", enneatype: "P" },
  { id: "q146", name: "Amable", value: false, category: "T", enneatype: "P" },
  { id: "q147", name: "Conformista", value: false, category: "D", enneatype: "P" },
  { id: "q148", name: "Estricto", value: false, category: "D", enneatype: "I" },
  { id: "q149", name: "Realizador", value: false, category: "T", enneatype: "I" },
  { id: "q150", name: "Intolerante", value: false, category: "M", enneatype: "I" },
  { id: "q151", name: "Expansivo", value: false, category: "M", enneatype: "L" },
  { id: "q152", name: "Dominante", value: false, category: "D", enneatype: "L" },
  { id: "q153", name: "Ingenioso", value: false, category: "T", enneatype: "L" },
  { id: "q154", name: "Sensible", value: false, category: "T", enneatype: "E" },
  { id: "q155", name: "Deferente", value: false, category: "M", enneatype: "E" },
  { id: "q156", name: "Voluble", value: false, category: "D", enneatype: "E" },
  { id: "q157", name: "Abstraído", value: false, category: "M", enneatype: "A" },
  { id: "q158", name: "Apático", value: false, category: "D", enneatype: "A" },
  { id: "q159", name: "Despierto", value: false, category: "T", enneatype: "A" },
  { id: "q160", name: "Alegre", value: false, category: "T", enneatype: "G" },
  { id: "q161", name: "Jovial", value: false, category: "M", enneatype: "G" },
  { id: "q162", name: "Genial", value: false, category: "D", enneatype: "G" },
];

export const partTwoData = [
  {
    id: "q1",
    selected: null,
    options: [
      { id: "q1", name: "Detallista", value: false, enneatype: "M" },
      { id: "q1", name: "Ágil", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q2",
    selected: null,
    options: [
      { id: "q1", name: "Emotivo", value: false, enneatype: "O" },
      { id: "q1", name: "Empático", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q3",
    selected: null,
    options: [
      { id: "q1", name: "Franco", value: false, enneatype: "L" },
      { id: "q1", name: "Flexible", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q4",
    selected: null,
    options: [
      { id: "q1", name: "Sofisticado", value: false, enneatype: "E" },
      { id: "q1", name: "Humilde", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q5",
    selected: null,
    options: [
      { id: "q1", name: "Distante", value: false, enneatype: "A" },
      { id: "q1", name: "Enojado", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q6",
    selected: null,
    options: [
      { id: "q1", name: "Miedoso", value: false, enneatype: "M" },
      { id: "q1", name: "Exitista", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q7",
    selected: null,
    options: [
      { id: "q1", name: "Adaptable", value: false, enneatype: "V" },
      { id: "q1", name: "Directo", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q8",
    selected: null,
    options: [
      { id: "q1", name: "Crítico", value: false, enneatype: "I" },
      { id: "q1", name: "Conformista", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q9",
    selected: null,
    options: [
      { id: "q1", name: "Multitareas", value: false, enneatype: "G" },
      { id: "q1", name: "Perceptivo", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q10",
    selected: null,
    options: [
      { id: "q1", name: "Autónomo", value: false, enneatype: "A" },
      { id: "q1", name: "Obediente", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q11",
    selected: null,
    options: [
      { id: "q1", name: "Considerado", value: false, enneatype: "O" },
      { id: "q1", name: "Gozador", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q12",
    selected: null,
    options: [
      { id: "q1", name: "Minucioso", value: false, enneatype: "I" },
      { id: "q1", name: "Creativo", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q13",
    selected: null,
    options: [
      { id: "q1", name: "Constante", value: false, enneatype: "I" },
      { id: "q1", name: "Atrayente", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q14",
    selected: null,
    options: [
      { id: "q1", name: "Confiado", value: false, enneatype: "P" },
      { id: "q1", name: "Dependiente", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q15",
    selected: null,
    options: [
      { id: "q1", name: "Drástico", value: false, enneatype: "L" },
      { id: "q1", name: "Disperso", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q16",
    selected: null,
    options: [
      { id: "q1", name: "Visionario", value: false, enneatype: "A" },
      { id: "q1", name: "Ingenioso", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q17",
    selected: null,
    options: [
      { id: "q1", name: "Estricto", value: false, enneatype: "I" },
      { id: "q1", name: "Voluble", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q18",
    selected: null,
    options: [
      { id: "q1", name: "Vergonzoso", value: false, enneatype: "E" },
      { id: "q1", name: "Ambicioso", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q19",
    selected: null,
    options: [
      { id: "q1", name: "Controlador", value: false, enneatype: "L" },
      { id: "q1", name: "Rutinario", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q20",
    selected: null,
    options: [
      { id: "q1", name: "Reposado", value: false, enneatype: "A" },
      { id: "q1", name: "Atolondrado", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q21",
    selected: null,
    options: [
      { id: "q1", name: "Perseverante", value: false, enneatype: "I" },
      { id: "q1", name: "Arriesgado", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q22",
    selected: null,
    options: [
      { id: "q1", name: "Desconfiado", value: false, enneatype: "M" },
      { id: "q1", name: "Acomplejado", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q23",
    selected: null,
    options: [
      { id: "q1", name: "Justo", value: false, enneatype: "L" },
      { id: "q1", name: "Deferente", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q24",
    selected: null,
    options: [
      { id: "q1", name: "Intuitivo", value: false, enneatype: "E" },
      { id: "q1", name: "Cerebral", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q25",
    selected: null,
    options: [
      { id: "q1", name: "Comprometido", value: false, enneatype: "M" },
      { id: "q1", name: "Impetuoso", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q26",
    selected: null,
    options: [
      { id: "q1", name: "Desenvuelto", value: false, enneatype: "G" },
      { id: "q1", name: "Adecuado", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q27",
    selected: null,
    options: [
      { id: "q1", name: "Modesto", value: false, enneatype: "P" },
      { id: "q1", name: "Absorbente", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q28",
    selected: null,
    options: [
      { id: "q1", name: "Adaptado", value: false, enneatype: "V" },
      { id: "q1", name: "Excéntrico", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q29",
    selected: null,
    options: [
      { id: "q1", name: "Diligente", value: false, enneatype: "V" },
      { id: "q1", name: "Olvidadizo", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q30",
    selected: null,
    options: [
      { id: "q1", name: "Tenaz", value: false, enneatype: "M" },
      { id: "q1", name: "Desprendido", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q31",
    selected: null,
    options: [
      { id: "q1", name: "Acomodaticio", value: false, enneatype: "P" },
      { id: "q1", name: "Vehemente", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q32",
    selected: null,
    options: [
      { id: "q1", name: "Comprensivo", value: false, enneatype: "O" },
      { id: "q1", name: "Aplicado", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q33",
    selected: null,
    options: [
      { id: "q1", name: "Gracioso", value: false, enneatype: "G" },
      { id: "q1", name: "Llano", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q34",
    selected: null,
    options: [
      { id: "q1", name: "Asertivo", value: false, enneatype: "V" },
      { id: "q1", name: "Caritativo", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q35",
    selected: null,
    options: [
      { id: "q1", name: "Riguroso", value: false, enneatype: "I" },
      { id: "q1", name: "Calificado", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q36",
    selected: null,
    options: [
      { id: "q1", name: "Recto", value: false, enneatype: "I" },
      { id: "q1", name: "Amigable", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q37",
    selected: null,
    options: [
      { id: "q1", name: "Categórico", value: false, enneatype: "L" },
      { id: "q1", name: "Dubitativo", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q38",
    selected: null,
    options: [
      { id: "q1", name: "Introvertido", value: false, enneatype: "A" },
      { id: "q1", name: "Amigote", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q39",
    selected: null,
    options: [
      { id: "q1", name: "Equitativo", value: false, enneatype: "I" },
      { id: "q1", name: "Cálido", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q40",
    selected: null,
    options: [
      { id: "q1", name: "Contenido", value: false, enneatype: "I" },
      { id: "q1", name: "Audaz", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q41",
    selected: null,
    options: [
      { id: "q1", name: "Indiferente", value: false, enneatype: "A" },
      { id: "q1", name: "Denunciante", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q42",
    selected: null,
    options: [
      { id: "q1", name: "Defensivo", value: false, enneatype: "M" },
      { id: "q1", name: "Intolerante", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q43",
    selected: null,
    options: [
      { id: "q1", name: "Planificador", value: false, enneatype: "G" },
      { id: "q1", name: "Sabio", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q44",
    selected: null,
    options: [
      { id: "q1", name: "Amable", value: false, enneatype: "P" },
      { id: "q1", name: "Intransigente", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q45",
    selected: null,
    options: [
      { id: "q1", name: "Complejo", value: false, enneatype: "E" },
      { id: "q1", name: "Mimetizado", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q46",
    selected: null,
    options: [
      { id: "q1", name: "Altruista", value: false, enneatype: "O" },
      { id: "q1", name: "Introspectivo", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q47",
    selected: null,
    options: [
      { id: "q1", name: "Genuino", value: false, enneatype: "E" },
      { id: "q1", name: "Idóneo", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q48",
    selected: null,
    options: [
      { id: "q1", name: "Mandón", value: false, enneatype: "L" },
      { id: "q1", name: "Ganador", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q49",
    selected: null,
    options: [
      { id: "q1", name: "Cómodo", value: false, enneatype: "P" },
      { id: "q1", name: "Arrogante", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q50",
    selected: null,
    options: [
      { id: "q1", name: "Prolijo", value: false, enneatype: "I" },
      { id: "q1", name: "Destacado", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q51",
    selected: null,
    options: [
      { id: "q1", name: "Aprensivo", value: false, enneatype: "O" },
      { id: "q1", name: "Metódico", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q52",
    selected: null,
    options: [
      { id: "q1", name: "Popular", value: false, enneatype: "V" },
      { id: "q1", name: "Comunicativo", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q53",
    selected: null,
    options: [
      { id: "q1", name: "Discreto", value: false, enneatype: "E" },
      { id: "q1", name: "Escéptico", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q54",
    selected: null,
    options: [
      { id: "q1", name: "Indiscreto", value: false, enneatype: "O" },
      { id: "q1", name: "Irreverente", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q55",
    selected: null,
    options: [
      { id: "q1", name: "Hablador", value: false, enneatype: "G" },
      { id: "q1", name: "Despistado", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q56",
    selected: null,
    options: [
      { id: "q1", name: "Cautivador", value: false, enneatype: "O" },
      { id: "q1", name: "Argumentador", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q57",
    selected: null,
    options: [
      { id: "q1", name: "Automotivado", value: false, enneatype: "V" },
      { id: "q1", name: "Sacrificado", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q58",
    selected: null,
    options: [
      { id: "q1", name: "Previsor", value: false, enneatype: "M" },
      { id: "q1", name: "Competente", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q59",
    selected: null,
    options: [
      { id: "q1", name: "Vanguardista", value: false, enneatype: "A" },
      { id: "q1", name: "Soñador", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q60",
    selected: null,
    options: [
      { id: "q1", name: "Callado", value: false, enneatype: "E" },
      { id: "q1", name: "Bromista", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q61",
    selected: null,
    options: [
      { id: "q1", name: "Dadivoso", value: false, enneatype: "O" },
      { id: "q1", name: "Formal", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q62",
    selected: null,
    options: [
      { id: "q1", name: "Concienzudo", value: false, enneatype: "I" },
      { id: "q1", name: "Espontáneo", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q63",
    selected: null,
    options: [
      { id: "q1", name: "Inadvertido", value: false, enneatype: "P" },
      { id: "q1", name: "Caprichoso", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q64",
    selected: null,
    options: [
      { id: "q1", name: "Enérgico", value: false, enneatype: "L" },
      { id: "q1", name: "Despierto", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q65",
    selected: null,
    options: [
      { id: "q1", name: "Prudente", value: false, enneatype: "E" },
      { id: "q1", name: "Seguro", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q66",
    selected: null,
    options: [
      { id: "q1", name: "Demoroso", value: false, enneatype: "M" },
      { id: "q1", name: "Mecanizado", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q67",
    selected: null,
    options: [
      { id: "q1", name: "Encantador", value: false, enneatype: "G" },
      { id: "q1", name: "Compasivo", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q68",
    selected: null,
    options: [
      { id: "q1", name: "Oportuno", value: false, enneatype: "V" },
      { id: "q1", name: "Enfocado", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q69",
    selected: null,
    options: [
      { id: "q1", name: "Decidido", value: false, enneatype: "L" },
      { id: "q1", name: "Cauteloso", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q70",
    selected: null,
    options: [
      { id: "q1", name: "Diplomático", value: false, enneatype: "P" },
      { id: "q1", name: "Eficiente", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q71",
    selected: null,
    options: [
      { id: "q1", name: "Imaginativo", value: false, enneatype: "G" },
      { id: "q1", name: "Fuerte", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q72",
    selected: null,
    options: [
      { id: "q1", name: "Cuidadoso", value: false, enneatype: "M" },
      { id: "q1", name: "Juguetón", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q73",
    selected: null,
    options: [
      { id: "q1", name: "Organizado", value: false, enneatype: "I" },
      { id: "q1", name: "Inspirado", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q74",
    selected: null,
    options: [
      { id: "q1", name: "Responsable", value: false, enneatype: "M" },
      { id: "q1", name: "Ocurrente", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q75",
    selected: null,
    options: [
      { id: "q1", name: "Bondadoso", value: false, enneatype: "P" },
      { id: "q1", name: "Sensible", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q76",
    selected: null,
    options: [
      { id: "q1", name: "Apto", value: false, enneatype: "V" },
      { id: "q1", name: "Culto", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q77",
    selected: null,
    options: [
      { id: "q1", name: "Atento", value: false, enneatype: "O" },
      { id: "q1", name: "Inagotable", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q78",
    selected: null,
    options: [
      { id: "q1", name: "Iracundo", value: false, enneatype: "L" },
      { id: "q1", name: "Competitivo", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q79",
    selected: null,
    options: [
      { id: "q1", name: "Ermitaño", value: false, enneatype: "A" },
      { id: "q1", name: "Rebaño", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q80",
    selected: null,
    options: [
      { id: "q1", name: "Conciliador", value: false, enneatype: "P" },
      { id: "q1", name: "Confrontacional", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q81",
    selected: null,
    options: [
      { id: "q1", name: "Cordial", value: false, enneatype: "M" },
      { id: "q1", name: "Resuelto", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q82",
    selected: null,
    options: [
      { id: "q1", name: "Investigador", value: false, enneatype: "A" },
      { id: "q1", name: "Realizador", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q83",
    selected: null,
    options: [
      { id: "q1", name: "Terrenal", value: false, enneatype: "V" },
      { id: "q1", name: "Emocional", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q84",
    selected: null,
    options: [
      { id: "q1", name: "Versátil", value: false, enneatype: "G" },
      { id: "q1", name: "Capaz", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q85",
    selected: null,
    options: [
      { id: "q1", name: "Leal", value: false, enneatype: "M" },
      { id: "q1", name: "Original", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q86",
    selected: null,
    options: [
      { id: "q1", name: "Distraído", value: false, enneatype: "G" },
      { id: "q1", name: "Apático", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q87",
    selected: null,
    options: [
      { id: "q1", name: "Impulsivo", value: false, enneatype: "L" },
      { id: "q1", name: "Controlado", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q88",
    selected: null,
    options: [
      { id: "q1", name: "Grave", value: false, enneatype: "L" },
      { id: "q1", name: "Jovial", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q89",
    selected: null,
    options: [
      { id: "q1", name: "Resignado", value: false, enneatype: "P" },
      { id: "q1", name: "Abnegado", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q90",
    selected: null,
    options: [
      { id: "q1", name: "Idealista", value: false, enneatype: "I" },
      { id: "q1", name: "Optimista", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q91",
    selected: null,
    options: [
      { id: "q1", name: "Pasional", value: false, enneatype: "O" },
      { id: "q1", name: "Desapegado", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q92",
    selected: null,
    options: [
      { id: "q1", name: "Único", value: false, enneatype: "E" },
      { id: "q1", name: "Genial", value: false, enneatype: "G" },
    ],
  },
  {
    id: "q93",
    selected: null,
    options: [
      { id: "q1", name: "Protector", value: false, enneatype: "L" },
      { id: "q1", name: "Cariñoso", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q94",
    selected: null,
    options: [
      { id: "q1", name: "Aislado", value: false, enneatype: "A" },
      { id: "q1", name: "Expansivo", value: false, enneatype: "L" },
    ],
  },
  {
    id: "q95",
    selected: null,
    options: [
      { id: "q1", name: "Tolerante", value: false, enneatype: "P" },
      { id: "q1", name: "Productivo", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q96",
    selected: null,
    options: [
      { id: "q1", name: "Activo", value: false, enneatype: "G" },
      { id: "q1", name: "Tranquilo", value: false, enneatype: "P" },
    ],
  },
  {
    id: "q97",
    selected: null,
    options: [
      { id: "q1", name: "Artístico", value: false, enneatype: "E" },
      { id: "q1", name: "Intelectual", value: false, enneatype: "A" },
    ],
  },
  {
    id: "q98",
    selected: null,
    options: [
      { id: "q1", name: "Perfeccionista", value: false, enneatype: "I" },
      { id: "q1", name: "Eficaz", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q99",
    selected: null,
    options: [
      { id: "q1", name: "Suspicaz", value: false, enneatype: "M" },
      { id: "q1", name: "Inflexible", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q100",
    selected: null,
    options: [
      { id: "q1", name: "Atractivo", value: false, enneatype: "V" },
      { id: "q1", name: "Refinado", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q101",
    selected: null,
    options: [
      { id: "q1", name: "Expresivo", value: false, enneatype: "O" },
      { id: "q1", name: "Reservado", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q102",
    selected: null,
    options: [
      { id: "q1", name: "Solitario", value: false, enneatype: "A" },
      { id: "q1", name: "Grupal", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q103",
    selected: null,
    options: [
      { id: "q1", name: "Pacífico", value: false, enneatype: "P" },
      { id: "q1", name: "Reformador", value: false, enneatype: "I" },
    ],
  },
  {
    id: "q104",
    selected: null,
    options: [
      { id: "q1", name: "Ansioso", value: false, enneatype: "M" },
      { id: "q1", name: "Impaciente", value: false, enneatype: "V" },
    ],
  },
  {
    id: "q105",
    selected: null,
    options: [
      { id: "q1", name: "Parco", value: false, enneatype: "I" },
      { id: "q1", name: "Sentimental", value: false, enneatype: "O" },
    ],
  },
  {
    id: "q106",
    selected: null,
    options: [
      { id: "q1", name: "Imponente", value: false, enneatype: "L" },
      { id: "q1", name: "Delicado", value: false, enneatype: "E" },
    ],
  },
  {
    id: "q107",
    selected: null,
    options: [
      { id: "q1", name: "Pasivo", value: false, enneatype: "P" },
      { id: "q1", name: "Cuestionador", value: false, enneatype: "M" },
    ],
  },
  {
    id: "q108",
    selected: null,
    options: [
      { id: "q1", name: "Servicial", value: false, enneatype: "O" },
      { id: "q1", name: "Confiable", value: false, enneatype: "M" },
    ],
  },
];
