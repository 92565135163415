
import { defineComponent, onMounted, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import { CoreStore } from "@/store";
import { getEnneatypeProfile } from "@/utils/calculateProfile";
import { IProfiles } from "@/store/models/ITest";
import Charts from "@/components/TestDetails/Charts.vue";
import Answers from "@/components/TestDetails/Answers.vue";
import { feedback } from "@/utils/extras";

export default defineComponent({
  name: "ProfessionalTestResult",

  components: { Charts, Answers },

  setup() {
    const route = useRoute();
    const coreStore = CoreStore();
    const state = reactive({
      isLoading: computed(() => coreStore.test.isLoading),
      professionalData: computed(() => coreStore.test.professionalData),
      profiles: {} as IProfiles | any,
    });

    onMounted(async () => {
      await coreStore.getTestData(route.params.id as string).then(() => {
        state.profiles = getEnneatypeProfile(state.professionalData.results);
      });
    });

    return {
      state,
      feedback,
    };
  },
});
