import { ITest } from "@/store/models/ITest";
import { calculateEnneatypes, calculateCoherence } from "@/utils/calculateEnneatype";
import { getEnneatypeProfile } from "@/utils/calculateProfile";
import { http } from "@/plugins/axios";
import * as _ from "lodash";

class TestService {
  async finishTest(test: ITest) {
    // PART A
    const enneatypesPartA = calculateEnneatypes(test.partA, "A");
    // console.log("PART A: ", enneatypesPartA[0], enneatypesPartA[1]);
    let triadI = 0,
      triadS = 0,
      triadP = 0,
      temperM = 0,
      temperF = 0,
      temperC = 0,
      temperS = 0;

    for (const [key, value] of Object.entries(enneatypesPartA[0])) {
      // TRIADS
      if (key === "L" || key === "P" || key === "I") {
        triadI += Number(value);
      } else if (key === "O" || key === "V" || key === "E") {
        triadS += Number(value);
      } else if (key === "A" || key === "M" || key === "G") {
        triadP += Number(value);
      }
      // TEMPER
      if (key === "E" || key === "M") {
        temperM += Number(value);
      } else if (key === "P" || key === "A") {
        temperF += Number(value);
      } else if (key === "L" || key === "I") {
        temperC += Number(value);
      } else if (key === "O" || key === "V" || key === "G") {
        temperS += Number(value);
      }
    }

    let intensityLevel: any = Object.values(enneatypesPartA[0]).reduce((acc: any, curr: any) => {
      acc = acc + curr;
      return acc;
    }, 0);
    intensityLevel = parseFloat((parseInt(intensityLevel) / 162).toFixed(2)) * 100;

    // PART B
    const enneatypesPartB = calculateEnneatypes(test.partB, "B");
    // console.log("PART B: ", enneatypesPartB);
    const coherencePercent = calculateCoherence(test.partA, test.partB);
    // console.log(coherencePercent);

    let coherenceLevel: any = Object.values(coherencePercent).reduce((acc: any, curr: any) => {
      acc = acc + curr;
      return acc;
    }, 0);
    coherenceLevel = parseFloat((parseInt(coherenceLevel) / 135).toFixed(2)) * 100;
    // console.log("coherenceLevel: ", coherenceLevel);

    const testResult = {
      // PART A
      intensity: enneatypesPartA[0],
      intensityBreakdown: enneatypesPartA[1],
      intensityLevel,
      triad: {
        I: triadI,
        S: triadS,
        P: triadP,
      },
      temper: {
        M: temperM,
        F: temperF,
        C: temperC,
        S: temperS,
      },
      // PART B
      coherence: enneatypesPartB[0],
      coherencePercent,
      coherenceLevel,
    };

    const enneatypeProfile = getEnneatypeProfile(testResult);

    // console.log(enneatypeProfile);
    const professionalExist: object = await http.get(`/professionals/get?email=${test.professionalData.email}`);

    let questionnaireId = null;

    console.log(professionalExist);

    // IF USER ALREADY DID A TEST SKIP IT
    if (_.isEmpty(professionalExist)) {
      delete test.professionalData.confirmTerms;

      await http.post("/professionals", { data: test.professionalData }).then(async (res) => {
        // console.log("professional ok");
        await http
          .post("/questionnaires", {
            data: {
              professionalId: res.data.id,
              answers: {
                partA: test.partA,
                partB: test.partB,
              },
              results: testResult,
            },
          })
          .then((res) => {
            questionnaireId = res.data.id;
          });
      });
    }

    return { intensityLevel, coherenceLevel, enneatypeProfile, questionnaireId };
  }

  async getProfessionals() {
    return await http.get("/professionals");
  }

  async getTestResult(professionalId: string) {
    return await http.get(`/questionnaires/professionals/${professionalId}`);
  }

  async updateTest(questionnaireId: string, data: Record<string, unknown>) {
    await http.put(`/questionnaires/${questionnaireId}`, { data });
  }

  async deleteTest(professionalId: string) {
    await http.delete(`/questionnaires/professionals/${professionalId}`);
  }

  async deleteProfessional(professionalId: string) {
    await http.delete(`/professionals/${professionalId}`);
  }
}

export default new TestService();
