
import { defineComponent, watchEffect, computed, reactive } from "vue";
import { CoreStore } from "@/store";

export default defineComponent({
  name: "Charts",

  props: {
    professionalData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const coreStore = CoreStore();
    const state = reactive({
      isLoading: computed(() => coreStore.test.isLoading),
      professionalData: props.professionalData,
      intensityFeatureChartData: [] as any,
      intensityBreakdownChartData: [] as any,
      intensityStretchChartData: [] as any,
      temperChartData: [] as any,
      triadChartData: [] as any,
      coherenceChartData: [] as any,
      coherencePercentChartData: [] as any,
    });

    watchEffect(() => {
      if (!state.isLoading) {
        // console.log(professionalId);
        // console.log(res.results);
        state.intensityFeatureChartData = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
          labels: Object.entries(state.professionalData.results.intensity).map(([key, value]) => key),
          datasets: [
            {
              label: "Eneatipo",
              backgroundColor: "#dc2626",
              // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
              data: Object.entries(state.professionalData.results.intensity).map(([key, value]) => value),
            },
          ],
        };
        state.intensityBreakdownChartData = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
          labels: Object.entries(state.professionalData.results.intensityBreakdown.breakdown).map(([key, value]) => key),
          datasets: [
            {
              type: "bar",
              label: "T",
              backgroundColor: "#dc2626",
              data: Object.entries(state.professionalData.results.intensityBreakdown.breakdown).map(
                ([key, value]: any) => value.slice(0, 1)[0]
              ),
            },
            {
              type: "bar",
              label: "M",
              backgroundColor: "#d95555",
              data: Object.entries(state.professionalData.results.intensityBreakdown.breakdown).map(
                ([key, value]: any) => value.slice(1, 2)[0]
              ),
            },
            {
              type: "bar",
              label: "D",
              backgroundColor: "#db8f8f",
              data: Object.entries(state.professionalData.results.intensityBreakdown.breakdown).map(
                ([key, value]: any) => value.slice(2, 3)[0]
              ),
            },
          ],
        };
        const sumT = Object.entries(state.professionalData.results.intensityBreakdown.breakdown)
          .map(([key, value]: any) => value.slice(0, 1)[0])
          .reduce((a, b) => a + b, 0);
        const sumM = Object.entries(state.professionalData.results.intensityBreakdown.breakdown)
          .map(([key, value]: any) => value.slice(1, 2)[0])
          .reduce((a, b) => a + b, 0);
        const sumD = Object.entries(state.professionalData.results.intensityBreakdown.breakdown)
          .map(([key, value]: any) => value.slice(2, 3)[0])
          .reduce((a, b) => a + b, 0);

        state.intensityStretchChartData = {
          labels: ["T", "M", "D"],
          datasets: [
            {
              type: "bar",
              label: "Tramo",
              backgroundColor: "#dc2626",
              barPercentage: 0.5,
              minBarLength: 25,
              data: [sumT, sumM, sumD],
            },
          ],
        };
        state.temperChartData = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
          labels: Object.entries(state.professionalData.results.temper).map(([key, value]) => key),
          datasets: [
            {
              backgroundColor: ["#dc2626", "#d95555", "#db8f8f", "#dbabab"],
              // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
              data: Object.entries(state.professionalData.results.temper).map(([key, value]) => value),
            },
          ],
        };
        state.triadChartData = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
          labels: Object.entries(state.professionalData.results.triad).map(([key, value]) => key),
          datasets: [
            {
              backgroundColor: ["#dc2626", "#d95555", "#db8f8f", "#dbabab"],
              // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
              data: Object.entries(state.professionalData.results.triad).map(([key, value]) => value),
            },
          ],
        };
        state.coherenceChartData = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
          labels: Object.entries(state.professionalData.results.coherence).map(([key, value]) => key),
          datasets: [
            {
              label: "Eneatipo",
              backgroundColor: "#2563eb",
              // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
              data: Object.entries(state.professionalData.results.coherence).map(([key, value]) => value),
            },
          ],
        };
        state.coherencePercentChartData = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
          labels: Object.entries(state.professionalData.results.coherencePercent).map(([key, value]) => key),
          datasets: [
            {
              label: "%",
              backgroundColor: "#2563eb",
              // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
              data: Object.entries(state.professionalData.results.coherencePercent).map(
                ([key, value]: any) => parseFloat((parseInt(value) / 15).toFixed(2)) * 100
              ),
            },
          ],
        };
      }
    });

    return {
      state,
    };
  },
});
