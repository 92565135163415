import { defineStore } from "pinia";
import { IProfessionalData } from "./models/ITest";
import QuestionnaireService from "@/services/questionnaire";
import { ITest } from "./models/ITest";

interface CoreState {
  auth: {
    session: Record<string, unknown> | null;
    error: Object | string | null;
    isLoading: boolean;
  };
  test: {
    professionalData: IProfessionalData;
    partA: Array<Object>;
    partB: Array<Object>;
    result: any | null;
    success: Object | string | null;
    error: Object | string | null;
    isLoading: boolean;
  };
  dashboard: {
    professionals: Array<Object>;
  };
}

export const CoreStore = defineStore({
  id: "store",

  state: (): CoreState => ({
    auth: {
      session: null,
      error: null,
      isLoading: false,
    },
    test: {
      professionalData: {} as IProfessionalData,
      partA: [],
      partB: [],
      result: {},
      success: {},
      error: {},
      isLoading: true,
    },
    dashboard: {
      professionals: [],
    },
  }),

  actions: {
    async finishTest() {
      const { professionalData, partA, partB } = this.test;
      this.test.result = {};
      await QuestionnaireService.finishTest({ professionalData, partA, partB } as ITest)
        .then((res) => {
          this.test.result = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProfessionals() {
      await QuestionnaireService.getProfessionals().then((res) => {
        this.dashboard.professionals = res.data.reverse() || [];
      });
    },

    async getTestData(professionalId: string) {
      this.test.isLoading = true;
      return await QuestionnaireService.getTestResult(professionalId)
        .then((res: any) => {
          this.test.professionalData = res;
          this.test.isLoading = false;
        })
        .catch(() => {
          this.test.isLoading = false;
        });
    },

    async updateTest(questionnaireId: string, data: Record<string, unknown>) {
      await QuestionnaireService.updateTest(questionnaireId, data);
    },

    async deleteTest(professionalId: string) {
      await QuestionnaireService.deleteTest(professionalId).then(async () => {
        await QuestionnaireService.deleteProfessional(professionalId);
      });
    },
  },
});
