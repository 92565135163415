
import { defineComponent } from "vue";
import Header from "@/layout/Header.vue";

export default defineComponent({
  name: "Home",
  components: {
    Header,
  },
});
