
import { defineComponent, reactive, ref, computed } from "vue";
import { useRouter } from "vue-router";
import AuthService from "@/services/auth";
import { CoreStore } from "@/store";
import { email, required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  name: "Login",
  setup() {
    const coreStore = CoreStore();

    const state = reactive({
      email: "",
      password: "",
      loading: computed(() => coreStore.auth.isLoading),
      error: computed(() => coreStore.auth.error),
    });

    const router = useRouter();
    const authError = ref(null);

    const isRequiredMsg = "Campo obligatorio";
    const rules = {
      email: {
        required: helpers.withMessage(isRequiredMsg, required),
        email: helpers.withMessage("Email inválido", email),
      },
      password: { required: helpers.withMessage(isRequiredMsg, required) },
    };

    const v$ = useVuelidate(rules, state);

    const handleSubmit = async () => {
      coreStore.auth.isLoading = true;

      await AuthService.login(state)
        .then((res: any) => {
          // console.log(res);
          coreStore.auth.session = res.user;
          localStorage.setItem("jwt", res.jwt);
          coreStore.auth.isLoading = false;
          coreStore.auth.error = null;
          router.push("/dashboard");
        })
        .catch(() => {
          coreStore.auth.isLoading = false;
          coreStore.auth.error = "correo y/o contraseña inválidas";
        });
    };

    return { v$, state, handleSubmit, authError };
  },
});
