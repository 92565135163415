import { createApp } from "vue";
import App from "./App.vue";
import "./theme.css";

const app = createApp(App);

/* PINIA | STORE */
import { createPinia } from "pinia";
app.use(createPinia());

/* ROUTER */
import router from "./router";
app.use(router);

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

/* PRIME VUE */
import "primevue/resources/themes/mdc-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import PrimeVue from "primevue/config";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import RadioButton from "primevue/radiobutton";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import ScrollPanel from "primevue/scrollpanel";
import Checkbox from "primevue/checkbox";
import Steps from "primevue/steps";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InlineMessage from "primevue/inlinemessage";
import Chart from "primevue/chart";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ScrollTop from "primevue/scrolltop";
import Skeleton from "primevue/skeleton";

app.use(PrimeVue);
app.component("Menubar", Menubar);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Password", Password);
app.component("RadioButton", RadioButton);
app.component("Calendar", Calendar);
app.component("Card", Card);
app.component("ScrollPanel", ScrollPanel);
app.component("Checkbox", Checkbox);
app.component("Steps", Steps);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("InlineMessage", InlineMessage);
app.component("Chart", Chart);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("ScrollTop", ScrollTop);
app.component("Skeleton", Skeleton);

/* THIRD PARTIES */

// FONT AWESOME 5
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

/* INITIALIZE APP */
app.mount("#app");
