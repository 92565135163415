import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import env from "@/config";

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = (error: AxiosResponse<any>) => {
  const { status } = error;

  switch (status) {
    case StatusCode.InternalServerError: {
      // Handle InternalServerError
      break;
    }
    case StatusCode.Forbidden: {
      // Handle Forbidden
      break;
    }
    case StatusCode.Unauthorized: {
      // Handle Unauthorized
      break;
    }
    case StatusCode.TooManyRequests: {
      // Handle TooManyRequests
      break;
    }
  }

  return Promise.reject(error);
};

const http = axios.create({
  baseURL: `${env.API_URL}/api`,
  headers,
});

http.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${env.API_KEY}`;
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const { response } = error;
    return handleError(response);
  }
);

export { http };
